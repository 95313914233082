import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import imgMark from '../../../assets/mark.png';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import imgHydraulicHosesForEvery from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/hydraulicHosesForEvery.jpg'
import imgInThermoplastic from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/InThermoplastic.jpg'
import ImageText from '../../../Components/ImageText/ImageText';
import imgSteelOrTextile from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/steelOrTextile.jpg'
import imgTestHydraulic from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/testedHydraulic.jpg'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgOnsiteAdvice from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/onSiteAdvice.jpg'
import imgProducedInProven from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/producedInProven.jpg'
import { Col, Container, Row } from 'react-bootstrap';
import imgSafetyEquipment from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/safetyEquipment.jpg'
import imgHighQuality from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/highQuality.jpg'
import imgSafeAndDependable from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/safeAndDependable.jpg'
import ImageCard from '../../../Components/ImageCard/ImageCard';
import Promoter from '../../../Components/Promoter/Promoter';
import imgTabletMobile from '../../../assets/Products/HosesAndHoseLines/HosesAndHoseLine/tabletMobile.png'
import imgHydraulicHoseLine from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/HydraulicHoseLine.jpg'
import HFTabs from '../../../Components/HfTabs/HfTabs';
import imgSeriesProduction from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/SeriesProduction.jpg'
import imgPlantInPlantProduction from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/PlantInPlantProduction.jpg'
import imgHoseManagement from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/HoseManagement.jpg'
import imgHydraulicLineInstallation from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/HydraulicLineInstallation.jpg'
import imgHoseTesting from '../../../assets/Products/HosesAndHoseLines/Hydraulic hose/HoseTesting.jpg'






function HydraulicHose() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const InThermoplastic1 = {
        image: imgInThermoplastic,
        title: 'In thermoplastic or elastomeric materials',
        description: 'Elastomeric hydraulic hoses excel through their good resistance to oils and ageing and retain their flexibility, even at temperatures down to -40 °C. The advantages of thermoplastic hoses include their low weight, excellent chemical resistance and flexibility. Elastomeric and thermoplastic hoses are extremely resistant to UV radiation and have high impulse load strength.',
    }

    const InThermoplastic2 = {
        image: imgSteelOrTextile,
        title: 'Steel or textile pressure carriers',
        description: "Two different types of winding are used for the steel reinforcement in the pressure carrier layer. Woven steel wires are used for braided hoses. The braid around the core layer of the hose is formed by weaving the wire alternately in two different directions. With spiral reinforced hoses, steel wire is wound around the inner layer. By using up to six layers, this form of construction is suitable for high pressures up to 500 bar. In the case of textile reinforced hoses, high-tech synthetic fibres such as aramid provide very good pressure stability. In addition, these hoses are flexible and light and are used in low-pressure applications up to 100 bar.",
    }

    const InThermoplastic3 = {
        image: imgTestHydraulic,
        title: 'Tested hydraulic hose lines with excellent availability',
        description: 'All hydraulic hoses from the HANSA-FLEX product range are regularly tested on in-house impulse and burst pressure test benches as part of our quality assurance system. HANSA-FLEX is also able to test hydraulic hoses and hydraulic hose/fitting combinations made to fulfil the customer’s specific requirements, such as suitability for higher pressures or alternative use scenarios. We also do not disappoint when it comes to availability. As Europe’s biggest hydraulics dealer and system supplier, we can count on our two main warehouses and network of branches to guarantee excellent availability.',
    }
    // ImageText On-site advice .......
    const OnsiteAdvice1 = {
        image: imgOnsiteAdvice,
        title: 'On-site advice from experienced technicians',
        description: 'Which hydraulic hose lines are most suitable for a specific purpose can be reliably determined only with experience and know-how. The fluid experts from HANSA-FLEX offer comprehensive advice and are delighted to show you your options for optimisation through material choice and system design. You can contact our teams in any of the HANSA-FLEX branches around the world. Our field service technicians will also be pleased to meet with you directly on site and advise on the installation of your hydraulic hose lines.',
    }
    const OnsiteAdvice2 = {
        image: imgProducedInProven,
        title: "Produced in proven HANSA-FLEX quality",
        description: 'HANSA-FLEX technicians manufacture preassembled, ready-to-install hydraulic hose lines in accordance with X-CODE data or your samples in over 450 branches throughout Germany. The hoses are professionally crimped, tested and cleaned. The highest quality standards apply to these preassembled hydraulic hose lines. The result is a leak-proof positive connection between the hydraulic hose and the connected fitting, which guarantees safety in use even at high pressures.',
    }

    const OnsiteAdvice3 = {
        image: imgHydraulicHoseLine,
        listItems: ["In elastomeric or thermoplastic material", "Pressure carriers with one or two layers of steel braid", "Pressure carriers up to six layers of spirally wound wire", "Textile pressure carriers for flexibility and weight savings", "Nominal diameters from 5 to 76 mm, pressure ranges up to 500 bar", "Components designed to work together perfectly"]
    }

    // features card carousel data
    const hoseLinesAdvantagesfeaturesData = [
        {
            title: "Excellent availability",
            description: "As Europe’s largest hydraulics equipment dealer, we can be relied upon for product flexibility and availability.",
            image: imgMark
        },
        {
            title: "In-house quality assurance",
            description: "Hydraulic hoses and hose lines are tested on our in-house test benches",
            image: imgMark
        },
        {
            title: "Advice and service",
            description: "With us you can be sure the hydraulic hose line you receive is perfect for your application",
            image: imgMark
        },
        {
            title: "Hose lines preassembled to the customer’s wishes",
            description: "Hydraulic hose lines are manufactured at HANSA-FLEX branches.",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Hose line series production",
            description: "Our central hose line series production facility in Bremen manufactures ready-to-install hydraulic, high-pressure, PTFE and industrial hose lines in large or small batches for OEM suppliers. With us, you can expect excellent process reliability and compliance with quality standards. We also adapt our procedures in warehouse product management and logistics to suit your requirements.",
            buttonName: "LEARN MORE",
            buttonLink: "/SeriesProduction",
            image: imgSeriesProduction
        },
        {
            title: 'Plant-in-plant production for process efficiency',
            description: "With plant-in-plant-production from HANSA-FLEX, you benefit by having hydraulic hoses and other components manufactured in harmony with your in-house processes.",
            listItems: ["The necessary components are made available just-in-sequence", "Any required unforeseen adaptations are quickly made on the spot", "Immediate availability of components, no warehousing costs"],
            buttonName: "LEARN MORE",
            buttonLink: "/Worksinworks",
            image: imgPlantInPlantProduction
        },
        {
            title: "Hose management with X-CODE",
            description: "Every hydraulic hose line is given an individual X-CODE to identify it uniquely. Any HANSA-FLEX branch can use the information in the X-CODE to manufacture the relevant article. Used in combination with the MY.HANSA-FLEX customer portal, the X-CODE is the perfect tool for our customers to manage all their hose lines. Customers can see the most important information, such as the next inspection or replacement date, at a glance in a readily comprehensible database.",
            buttonName: "LEARN MORE",
            buttonLink: "/hose-management",
            image: imgHoseManagement
        },
        {
            title: "Pipe and hose installation for complete plants",
            description: "Our service technicians and installation teams install all the pipes and hoses on machines, systems or mobile hydraulic units. To do this they first identify the potential for optimisation, taking into account positions, dimensions, design, material choice and efficiency. The next step is to agree a time window for the work with you and then implement the required measures.",
            buttonName: "LEARN MORE",
            buttonLink: "/installation-of-pipe-and-hoseline-systems",
            image: imgHydraulicLineInstallation
        },
        {
            title: "Increased safety thanks to professional hose testing",
            description: "Hydraulic hoses and connections must be regularly inspected. The safety of personnel, hydraulic systems and machines depends on hydraulic hose lines being free of faults and defects. Testing takes into account the DGUV rules and the German Industrial Health and Safety Act (BetrSichV) as well as the relevant product and testing standards. The experts at HANSA-FLEX are familiar with all the applicable legislation and guidelines so that all testing complies with the legal requirements.",
            buttonName: "LEARN MORE",
            buttonLink: "/dguv-inspection",
            image: imgHoseTesting
        },
    ];

    const tabTitles = [
        { title: "Series production", eventKey: "Series production" },
        { title: "Plant-in-plant production", eventKey: "Plant-in-plant production" },
        { title: "Hose management", eventKey: "Hose management" },
        { title: "Hydraulic line installation", eventKey: "Hydraulic line installation" },
        { title: "Hose testing", eventKey: "Hose testing" },
    ];

    return (
        <div>
            <CenterStage
                imageSrc={imgHydraulicHosesForEvery}
                title={"HYDRAULIC HOSES FOR EVERY APPLICATION"}
                paragraph={"Hydraulic hoses transfer fluid safely through the system. Their flexibility means they need very little installation space. The HANSA-FLEX range includes elastomeric and thermoplastic hoses with pressure carriers incorporating textile or steel braid or several inlays of spirally wound steel wire. This enables them to carry media at pressures of up to 500 bar. Hoses find use in numerous applications in mobile and stationary hydraulics."}
            //TODO: button (All products)
            />
            <div>
                <SectionHeader
                    title={"HIGH-QUALITY HYDRAULIC HOSES FROM HANSA-FLEX"}
                    subtitle={"All hydraulic hoses for low, medium, high-pressure applications and suction hoses are manufactured from high-grade materials and are subjected to extensive testing."}
                />
            </div>

            <div>
                <ImageText
                    data={InThermoplastic1} />

                <ImageText
                    data={InThermoplastic2}
                    left={false} />

                <ImageText
                    data={InThermoplastic3} />
            </div>

            <div>
                <Promoter
                    title={"CONFIGURE HOSE ASSEMBLIES QUICKLY AND EASILY YOURSELF"}
                    checklistItems={["Nominal widths 6 to 32, temperature range -40° C to + 100° C.", "Connection types: press nipple, press fittings type Interlock"]}
                    btnLabel1={"CONFIGURE NOW"}
                    btnLabel2={"LEARN MORE"}
                    btnLabel1Link={"https://shop.hansa-flex.cl/es_ES/configurator"}
                    btnLabel2Link={"/hose-line-config"}
                    imageUrl={imgTabletMobile}
                />
            </div>

            <div>
                <SectionHeader
                    title={"HOSE LINES FROM HANSA-FLEX COME WITH THESE ADVANTAGES"}
                    subtitle={"With its own testing laboratory, two central warehouses and an extensive network of branches, HANSA-FLEX can fulfil all customer wishes quickly and reliably."}
                />

                <FeatureCardCarousel
                    featuresData={hoseLinesAdvantagesfeaturesData} />
            </div>

            <div>
                <ImageText
                    data={OnsiteAdvice1} />

                <ImageText
                    data={OnsiteAdvice2}
                    left={false} />

            </div>

            <div>
                <SectionHeader
                    title={"HIGH-QUALITY HYDRAULIC HOSE LINES FOR EVERY APPLICATION"}
                    subtitle={"The HANSA-FLEX portfolio includes hydraulic hoses in numerous designs and material combinations for all applications in mobile and stationary hydraulics."}
                />
                <ImageText
                    data={OnsiteAdvice3}
                />
            </div>

            {/*TODO red CTA*/} or {/* TODO: TextPoster */}

            <div>
                <SectionHeader
                    title={"HOSE-RELATED SERVICES"} 
                    subtitle={"As well as manufacturing hose lines in series production facilities, HANSA-FLEX also offers various solutions for manufacturing customised, preassembled solutions for you directly on site. In addition, our experienced fluid experts can help and advise you."}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"SELECTED HANSA-FLEX PRODUCTS FOR YOUR HYDRAULIC HOSES"}

                />

                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgSafetyEquipment} learnMoreLink={"/hose-accessories"}
                                title="Safety equipment and accessories"
                                desc={"We supply a wide range of suitable accessories for your hydraulic hose lines to provide, for example, rubbing protection and hose restraint."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgHighQuality} learnMoreLink={"/hose-fitting"}
                                title="High-quality hose fittings"
                                desc={"HANSA-FLEX hose fittings are designed to perfectly match the hydraulic hoses. They also receive a zinc-nickel surface coating as standard."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgSafeAndDependable} learnMoreLink={"/coupling"}
                                title="Safe and dependable hydraulic couplings"
                                desc={"Couplings quickly connect and disconnect components. At HANSA-FLEX, you can find the perfect quick release coupling for every field of use."} /></Col>
                        </Row>

                    </Container>
                </div>

            </div>


        </div>
    );
}

export default HydraulicHose;


