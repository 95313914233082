import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgEngineering_Centerspread from '../../../assets/Services/Engineering/Engineering_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import ImageText from '../../../Components/ImageText/ImageText'

import { Container, Row, Col } from 'react-bootstrap';
import imgEngineering_Imagecard_01 from '../../../assets/Services/Engineering/Engineering_Imagecard_01.jpg'
import imgEngineering_Imagecard_02 from '../../../assets/Services/Engineering/Engineering_Imagecard_02.jpg'
import imgEngineering_Imagecard_03 from '../../../assets/Services/Engineering/Engineering_Imagecard_03.jpg'
import imgEngineering_Imagecard_04 from '../../../assets/Services/Engineering/Engineering_Imagecard_04.jpg'
import imgEngineering_Imagecard_05 from '../../../assets/Services/Engineering/Engineering_Imagecard_05.jpg'
import imgEngineering_Imagecard_06 from '../../../assets/Services/Engineering/Engineering_Imagecard_06.jpg'

import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import imgEngineering_Imagetext_01 from '../../../assets/Services/Engineering/Engineering_Imagetext_01.jpg'
import imgEngineering_Imagetext_02 from '../../../assets/Services/Engineering/Engineering_Imagetext_02.jpg'
import imgEngineering_Imagetext_03 from '../../../assets/Services/Engineering/Engineering_Imagetext_03.jpg'
import imgEngineering_Imagetext_04 from '../../../assets/Services/Engineering/Engineering_Imagetext_04.jpg'
import imgEngineering_Imagetext_05 from '../../../assets/Services/Engineering/Engineering_Imagetext_05.jpg'


const Engineering = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;



    const featuresData = [
        {
            title: "Optimum performance",
            description: "Products engineered by HANSA-FLEX are designed to work together perfectly.",
            image: imgMark
        },
        {
            title: "Expert advice from the very beginning",
            description: "The HANSA-FLEX prototype manufacturing team offers specialist knowledge from the first day to series production readiness.",
            image: imgMark
        },
        {
            title: "All from the same source",
            description: "Pre-kitted component groups can be managed as individual parts in the kanban inventory.",
            image: imgMark
        },
        {
            title: "Your data in 3D",
            description: "Reusable containers, no use of cardboard boxes, reduced transport requirement and weight.",
            image: imgMark
        }
        // Add more objects for additional FeaturesCard data
    ];



    const Engineering_Imagetext_01 = {
        image: imgEngineering_Imagetext_01,
        title: '3D models for design and simulation',
        description: ['Use our 3D models for your system design. We make 3D digital models of our hose line and pipeline components available for you to download. You can integrate these 3D models and CAD product information of components quite easily into your models and design drawings. This saves you time as you do not have to model or draw our components from the catalogue data and you can check the installation and connection situations immediately.'],
    }

    const Engineering_Imagetext_02 = {
        image: imgEngineering_Imagetext_02,
        title: 'CAD and ECAD for the theoretical design of components',
        description: ['HANSA-FLEX uses highly capable CAD and ECAD software to create design drawings and circuit diagrams for electrical and hydraulic systems. These systems are designed to meet the customer’s exact requirements. Virtual feasibility checks are made on each drawing, which allows the designers to see whether the project can actually be built in reality. Only when all the theoretical checks have been successfully completed are the data passed to the production floor.'],
    }


    const Engineering_Imagetext_03 = {
        image: imgEngineering_Imagetext_03,
        title: 'Highest design standards for series production',
        description: ['The 3D drawings produced by our technicians specify the high HANSA-FLEX quality standard to which the suppliers are to manufacture all the components. Thus we achieve high process reliability and can offer you an optimum price-performance ratio. Regardless of where a component is manufactured, it always has an identical appearance. Standardised production means the likelihood of tolerance errors when manufacturing hydraulic machines and systems is reduced dramatically.'],
    }


    const Engineering_Imagetext_04 = {
        image: imgEngineering_Imagetext_04,
        title: 'Design of replacement parts',
        description: 'You need a replacement for a made-to-measure valve, a cylinder, pipeline or other component but they are no longer available? We scan your component’s geometry using laser technology, create a new design drawing and manufacture the product for you.',
        listitems: ['Design and manufacture of all types of fluid technology components', 'Exact component geometry captured using a high-precision measuring arm', 'Can also be done quickly at short notice, if required'],
    }

    const Engineering_Imagetext_05 = {
        image: imgEngineering_Imagetext_05,
        title: 'Design improvements',
        description: ['HANSA-FLEX uses highly capable CAD and ECAD software to create design drawings and circuit diagrams for electrical and hydraulic systems. These systems are designed to meet the customer’s exact requirements. Virtual feasibility checks are made on each drawing, which allows the designers to see whether the project can actually be built in reality. Only when all the theoretical checks have been successfully completed are the data passed to the production floor.'],
    }




    return (
        <div>

            <CenterStage
                imageSrc={imgEngineering_Centerspread}
                title={"ENGINEERING AND PROJECT PLANNING WITH HANSA-FLEX"}
                paragraph={"The experienced specialists at HANSA-FLEX support you in all tasks associated with the engineering and project planning of hydraulic systems and components from design to manufacture. You can rely on our specialist knowledge of fluid technology in the implementation of prototypes, development of hydraulic units and other in-house product developments."}
            />


            <SectionHeader
                title={"DESIGNED AND MANUFACTURED FOR YOU BY HANSA-FLEX"}
                subtitle={""}
            />


            <Container className="custom-container">
                <Row>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgEngineering_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="In-house product series from HANSA-FLEX" desc="The high-quality components developed in-house by our engineers have crucial advantages when it comes to installation and servicing." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                        <ImageCard image={imgEngineering_Imagecard_02} learnMoreLink="/metal-hoses" title="Manufacture of prototypes to the customer’s individual requirements" desc="HANSA‑FLEX designs and develops prototypes to meet the customer’s individual requirements in the form of specifications, drawings or samples and supports the whole" />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgEngineering_Imagecard_03} learnMoreLink="/Industrial-hoses" title="Hydraulic power unit manufacture made-to-measure" desc="HANSA-FLEX designs and manufactures high-quality hydraulic power units to meet the customer’s individual wishes, taking into account any special requirements." />
                    </Col>

                </Row>
            </Container>


            <SectionHeader
                title={"A COMPLETE RANGE OF ENGINEERING SERVICES"}
                subtitle={"HANSA-FLEX Engineering performs in-house development, prototype and power unit manufacture and system design. We are able to support customers at every stage in the development of high-quality products from planning to series production."}
            />
            <div>
                <FeatureCardCarousel featuresData={featuresData} />
            </div>

            <SectionHeader
                title={"DESIGNED TO THE LATEST STATE OF THE TECHNOLOGY"}
                subtitle={"Design is elemental to engineering. Our precisely prepared digital design drawings and 3D models make system design much easier and ensure the highest manufacturing quality."}
            />

            <div >
                <br />
                <ImageText data={Engineering_Imagetext_01} left={false} />
            </div>

            <div >
                <br />
                <ImageText data={Engineering_Imagetext_02} />
            </div>

            <div >
                <br />
                <ImageText data={Engineering_Imagetext_03} left={false} />
            </div>

            {/* <SectionHeader
                title={"FIND OUT MORE ABOUT IN-HOUSE DEVELOPMENTS AT HANSA-FLEX"}
                subtitle={""}
            /> */}
            {/* TODO: HFTab */}

            <SectionHeader
                title={"REMANUFACTURE IDENTICAL VERSIONS OF COMPONENTS AND IMPROVE THEIR DESIGN"}
                subtitle={""}
            />

            <div >
                <br />
                <ImageText data={Engineering_Imagetext_04} left={false} />
            </div>

            <div >
                <br />
                <ImageText data={Engineering_Imagetext_05} />
            </div>

            <SectionHeader title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"} />

            <Container className="custom-container">
                <Row>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgEngineering_Imagecard_04} learnMoreLink="/hydraulic-hoses" title="HANSA-FLEX hydraulic hoses" desc="HANSA-FLEX hydraulic hoses, some of which are developed in-house, fulfil the highest quality standards in the industry." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                        <ImageCard image={imgEngineering_Imagecard_05} learnMoreLink="/metal-hoses" title="High-quality pipe fittings" desc="Pipe fittings were one of the first in-house developments at HANSA-FLEX. Even today, these hydraulic components are a safe way of connecting pipelines." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgEngineering_Imagecard_06} learnMoreLink="/hydraulic-hoses" title="Reliable sealing technology" desc="A hydraulic system is only as good as its seals. Just one defective seal can lead to long downtimes. HANSA-FLEX helps to prevent this." />
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Engineering;