import React, { useEffect } from 'react';
import HFTab from '../../../Components/HfTabs/HfTabs';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import ImageText from '../../../Components/ImageText/ImageText';
import Promoter from '../../../Components/Promoter/Promoter';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';

import imgFluidService from '../../../assets/Services/FluidManagement/fluidMangement.jpg'
import imgOilAnalysis from '../../../assets/Services/FluidManagement/oilAnalysis.jpg'
import imgOilCleaning from '../../../assets/Services/FluidManagement/oilCleaning.jpg'
import imgFluidCard3 from '../../../assets/Services/FluidManagement/fluid_service.jpg'
import imgAvailability from '../../../assets/Services/FluidManagement/24_7_availability.jpg'
import imgAllAboutOil from '../../../assets/Services/FluidManagement/all_about_oil.jpg'
import imgEverythingOilDo from '../../../assets/Services/FluidManagement/everything_oil_must_do.jpg'
import imgOilchnaging from '../../../assets/Services/FluidManagement/more_than_changing_oil.jpg'
import imgMonitoring from '../../../assets/Services/FluidManagement/monitoring.jpg'
import imgPromotorImage from '../../../assets/Services/FluidManagement/promotor.png'
// import pdf from '../../assets/Services/FluidManagement/'


const FluidService = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    //ImageText Data
    const oilAnalysis = {
        image: imgOilAnalysis,
        title: 'Comprehensive oil analysis',
        description: "Whether mobile or stationary hydraulics: oil analysis forms the foundation for optimum oil care. Only if fluid-related problems are recognised in good time and measures to rectify them taken can expensive downtime and major replacements be prevented. For this reason, HANSA-FLEX Fluid Service offers comprehensive oil analyses on site and in the laboratory.",
        buttonName: "READ MORE"
    }

    const oilCleaning = {
        image: imgOilCleaning,
        title: 'Efficient oil cleaning',
        description: 'By regularly filtering your oil, you can ensure that your hydraulic systems continue to function properly for longer. Solid and liquid contaminants cause abrasion, increase wear and promote corrosion. The HANSA-FLEX fluid experts clean your oil professionally and find the perfect filter solutions for your field of application.',
        buttonName: "READ MORE"
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Everything works out better",
            description: "Less damage from contamination, fewer failures and less downtime",
            image: imgMark
        },
        {
            title: "Longer service life",
            description: "Your machines and systems last much longer",
            image: imgMark
        },
        {
            title: "Professional expertise",
            description: "Our trained fluid specialists look after your oil and avoid damage to your systems",
            image: imgMark
        },
        {
            title: "Know what’s wrong",
            description: "Expert diagnoses find what’s wrong before it causes expensive damage",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Everything oil must do",
            description: "Hydraulic oil has to withstand high stresses. The pressure fluids standard DIN 51524 specifies the minimum requirements of hydraulic fluids. Other requirements:",
            listItems: ["Shear stability (resistance to changes in viscosity)", "Filterability", "Low hygroscopicity (the tendency of an oil to absorb water), and resistance to thermal loads"],
            image: imgEverythingOilDo
        },
        {
            title: "More than just changing the oil",
            description: "Fluid management involves more than just regular oil changes. Oil cleaning and analyses can help prevent failures and downtime. Where a hydraulic system has been carefully designed to achieve a specific performance, the requirements of the components used such as fluid and filtration solutions are particularly high. With professional advice from the fluid experts, you can lay the foundations for:",
            listItems: ["Operational reliability", "Cost-effective operation", "Long service life"],
            image: imgOilchnaging
        },
        {
            title: "Fluid condition monitoring",
            description: "Our experts continuously monitor the condition of the oil using oil condition sensors. This allows changes such as rising contamination levels to be recognised and rectified. The advantages:",
            listItems: ["Detect malfunctions in systems while they are operating", "Quick rectification of damage", "Reduced risk of failure of equipment, machines and systems", "Continual optimisation of fluid management"],
            image: imgMonitoring
        },
    ];

    const tabTitles = [
        { title: "Requirements", eventKey: "Requirements" },
        { title: "Fluid management", eventKey: "Fluid management" },
        { title: "Monitoring", eventKey: "Monitoring" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgFluidService}
                    title={"360° FLUID MANAGEMENT FROM HANSA-FLEX"}
                    paragraph={"Oil care is a key factor in ensuring trouble-free operation of hydraulic systems and plants. Contaminants in the fluid can have serious effects. From the analysis to the cleaning of the fluid right up to continuous condition monitoring during operation, HANSA‑FLEX fluid experts support you in all aspects of oil care for your systems and machines."}
                />
            </div>

            <div>
                <SectionHeader
                    title={'COMPREHENSIVE HYDRAULIC OIL CARE FROM THE EXPERTS'}
                    subtitle={'With the benefit of know-how from HANSA-FLEX fluid experts, you can avoid damage and downtimes'}
                />
                <ImageText
                    data={oilAnalysis} />
                <ImageText
                    data={oilCleaning} left={false} />
            </div>

            {/* // TODO: 10 Rules element ----Not made */}
            {/* // TODO: Client reviews */}

            <div>
                <SectionHeader
                    title={"OIL CARE – WHY IT’S WORTH IT"}
                    subtitle={"All the benefits at a glance"}
                />

                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"THE 3 MOST IMPORTANT FACTS ABOUT THE USE OF HYDRAULIC OIL"}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"DAMAGE DETECTION AND FLUID-RELATED SYSTEM OPTIMISATION"}
                    subtitle={"In the event of damage, the experienced HANSA-FLEX fluid and hydraulics specialists perform an analysis on site. They quickly determine the reasons for the failure and provide extensive assistance with the implementation of all the measures required to rectify the damage. The experts also devise an action plan for your fluid management, so that oil-related system failures become a thing of the past."}
                />
                <Promoter
                    title={"Everything about clean hydraulic oil in a nutshell"}
                    checklistItems={["Hydraulic fluid is an important system component and crucially significant to the design, operation and maintenance of hydraulic equipment. You can find everything you need to know about the undervalued component hydraulic oil in our brochure."]}
                    imageUrl={imgPromotorImage}
                    btnLabel1={"Download PDF"}
                    btnLabel1Link={'pdfLink'}
                // add pdf link
                />
            </div>

            <div>
                <SectionHeader
                    title={"SOMETHING THAT MAY ALSO INTEREST YOU"}
                />

                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={imgAllAboutOil}
                                title="All about oil: Fluid Service"
                                desc={"HANSA-FLEX Fluid Service carries out oil analyses directly on site, performs oil cleaning or the complete replacement of the fluid and advises on all aspects of fluid..."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgAvailability}
                                title="24/7 availability: Rapid Hydraulic Service"
                                desc={"We are there for you in an emergency, all round the clock: our Rapid Hydraulic Service can quickly replace defective components such as hose lines."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgFluidCard3}
                                title="You Can Count on Us: Industrial Service"
                                desc={"Experienced specialists support you as qualified persons in inspecting your facilities and equipment according to current legal regulations..."} /></Col>
                        </Row>
                    </Container>
                </div>

            </div>

        </div>
    )
}

export default FluidService