import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const NumberGrid = ({ numbers, subtitles }) => {
    const { t } = useTranslation();
    return (
        <Container style={{ margin: '0 auto' }}>
            <Row className="justify-content-center">
                {numbers.map((number, index) => (
                    <Col key={index} className="text-center">
                        <div className="number" style={{ color: '#28588F', fontSize: '4rem' }}>{t(number)}</div>
                        <div className="subtitle" style={{ fontSize: '1.4rem' }}>{t(subtitles[index])}</div>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default NumberGrid;
