import React, { useEffect } from 'react';
import ImageDescription from '../../Components/ImageDescription/ImageDescription';
import imgGoethe from '../../assets/news-events/goethe.jpg';


function GoetheEvent() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div>
            <ImageDescription
                title={"Empowering Students: Insights into Career Opportunities and Language Skills"}
                paragraphs={[
                    "On July 31st, 2023, HANSA-FLEX India joined hands with the Goethe Institute, Pune, for an event called 'Career with German.' Around 3000 students, both online and in-person, tuned in to learn from us."
                    , "Mahesh Kulkarni, who leads HANSA-FLEX India's Global Capabilities Center, talked about what our company does. He explained the different things we work on, showing how we always strive to do our best."
                    , "Namrata Damle, who speaks German fluently and has a lot of experience, also spoke. She shared why knowing German is useful for jobs. She mentioned some job openings we have right now that need this skill."
                    , "This event was a great chance for students to find out about what HANSA-FLEX India does, why German can be helpful, and what job opportunities we have. We're glad we could be a part of it and help students on their career path."
                ]}
                imageSrc={imgGoethe}
            />
        </div>
    );
}

export default GoetheEvent;