import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import img_Product_Pipeend_Centerspread from '../../../assets/Products/Pipelines/Pipe-end processing/Products_Pipeend_centerstage.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import img_Products_Pipeend_imagetext1 from '../../../assets/Products/Pipelines/Pipe-end processing/Products_Pipeend_imagetext1.jpg'
import img_Products_Pipeend_imagetext2 from '../../../assets/Products/Pipelines/Pipe-end processing/Products_Pipeend_imagetext2.jpg'
import img_Products_Pipeend_imagetext3 from '../../../assets/Products/Pipelines/Pipe-end processing/Products_Pipeend_imagetext3.jpg'
import img_Products_Pipeend_imagetext4 from '../../../assets/Products/Pipelines/Pipe-end processing/Products_Pipeend_imagetext4.jpg'
import img_Products_Pipeend_imagetext5 from '../../../assets/Products/Pipelines/Pipe-end processing/Products_Pipeend_imagetext5.jpg'
import img_Products_Pipeend_imagetext6 from '../../../assets/Products/Pipelines/Pipe-end processing/Products_Pipeend_imagetext6.jpg'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import { Container, Row, Col } from 'react-bootstrap';
import imgProductPipeendimagecard1 from '../../../assets/Products/Pipelines/Pipe-end processing/Pipeend_Imagecard1.jpg'
import imgProductPipeendimagecard2 from '../../../assets/Products/Pipelines/Pipe-end processing/Pipeend_Imagecard2.jpg'
import imgProductPipeendimagecard3 from '../../../assets/Products/Pipelines/Pipe-end processing/Pipeend_Imagecard3.jpg'
import AllConnections from '../../../assets/Products/Pipelines/Pipe-end processing/AllConnections.jpg'

import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import ImageText from '../../../Components/ImageText/ImageText';
import imgMark from '../../../assets/mark.png'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'

const PipeendProcessing = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;



  const ProductPipebendimagetext1 = {
    image: img_Products_Pipeend_imagetext1,
    title: 'Cutting ring, threaded and flange fittings from HANSA-FLEX',
    description: ['HANSA-FLEX preassembles high-quality cutting ring, threaded and flange fittings for joining pipes in all commercially available types. Customised forms of swaging and combinations of different nominal diameters and connection types are also available on request from HANSA-FLEX. The range includes, for example:'],
    listItems:["Cutting rings in accordance with DIN 3861", "Threads in accordance with DIN, EN, ISO or SAE", "Flanges in accordance with DIN, EN, ISO, SAE, CETOP, ANSI, JIS and VG"]
  }

  const ProductPipebendimagetext2 = {
    image: img_Products_Pipeend_imagetext2,
    title: 'Flare fittings from HANSA-FLEX',
    description: ['Flare fittings from HANSA-FLEX are optimally suited for applications in mobile hydraulics, for example on construction machines or agricultural machinery. Forming at the tube end is realised in a material-saving manner using rotary forming technology. A continually expanding selection of tool variants enables us to process tube ends in many different ways. HANSA-FLEX manufactures many kinds of flare connections including these:'],
    listItems:["Flare 37° sealing cone JIC (Triple-Lok), ISO 8434-2/SAE J 514", "Brake pipe and coupling connections type D-E-F, DIN 74234"]
  }


  const ProductPipebendimagetext3 = {
    image: img_Products_Pipeend_imagetext3,
    title: 'Innovative HF-Form pipe forming system',
    description: ['With its in-house developed HF-Form pipe forming system, HANSA-FLEX sets new standards for sealing performance, cleanliness, flow characteristics, reliability and ease of installation. The integrated over-tightening protection simplifies pipe system installation. The pipes are connected using a standardised union nut. No special parts or special tools are required during manufacture or assembly. Dispensing with a cutting ring also reduces the risk of faulty assembly.'],
  }


  const ProductPipebendimagetext4 = {
    image: img_Products_Pipeend_imagetext4,
    title: 'Special fittings instead of towers of screw fittings',
    description: ['HANSA-FLEX manufactures special fittings for a diverse range of industries and applications. Multiply bent fittings, any form of adapter with a variety of threads, including complex collectors and manifolds. The design is always decided in consultation with the customer, recorded on a drawing and then manufactured. Special fittings avoid having to connect several adapters and connection pieces one after another. This in turn considerably reduces material consumption, weight and the risk of leaks.'],
  }

  const ProductPipebendimagetext5 = {
    image: img_Products_Pipeend_imagetext5,
    title: 'Special procedures for soldering and welding',
    description: ['HANSA-FLEX offers all available soldering processes for forming safe connections. Continuous soldering furnace for hard and high-temperature soldering under protective gas, for example soldering a length of 16 m at a rate of approximately 100 kg/h at a maximum temperature of 1,130 °C. HANSA-FLEX is certified to carry out welding processes in accordance with GSI, DIN EN 15085 2, DIN 2303 Q2 BK1 and DIN EN ISO 3834. HANSA-FLEX also has an orbital welding machine. This technique produces a consistently high and reproducible weld quality irrespective of the throughput.'],
  }


  const ProductPipebendimagetext6 = {
    image: img_Products_Pipeend_imagetext6,
    title: 'Face-to-face advice on site',
    description: ['A wide range of connection options, materials and surface treatments come into consideration for different applications. HANSA-FLEX therefore sees comprehensive face-to-face advice on site as very important in finding the perfect solution in every field of use. By doing this, every customer benefits from the decades of experience gained by our fluid technology technicians and specialists across many industries.'],
  }

  const ProductPipebendimagetext7 = {
    image: AllConnections,
    listItems: ["90° ORFS, ISO 8434-3, tumbling process and rotary forming", "24° sealing cone, ISO 8434-1/DIN 2353", "Double-edge cutting ring, DIN 3861", "Brake pipe and coupling connections type D-E-F, DIN 74234", "Flare 37° sealing cone JIC (Triple-Lok), ISO 8434-2/SAE J 514", "Upsetting as bead for spring band clamp DIN 3021-3"],
  }

  //  FeatureCardData

  const featuresData = [
    {
      title: "Highest quality and precision",
      description: "HANSA-FLEX relies on state-of-the-art machines and computer-controlled manufacturing processes.",
      image: imgMark
    },
    {
      title: "Rapid availability",
      description: "Orders for one-off or series-manufactured components fulfilled equally quickly.",
      image: imgMark
    },
    {
      title: "All commonly available forms",
      description: "Customers can obtain all commonly available connection variants for their pipe connections from HANSA-FLEX.",
      image: imgMark
    },
    {
      title: "Customised manufacture",
      description: "HANSA-FLEX manufactures special fittings for systems and machines precisely to the customer’s requirements.",
      image: imgMark
    }
    // Add more objects for additional FeaturesCard data
  ];
  return (
    <div>
      <div>
        <CenterStage
          imageSrc={img_Product_Pipeend_Centerspread}
          title={"PIPE-END PROCESSING AND SPECIAL FITTINGS MADE-TO-MEASURE"}
          paragraph={"HANSA-FLEX offers a range of connection types produced by different methods of pipe-end processing. In addition to manufacturing high-quality cutting ring and flange connections, HANSA-FLEX also provides a wide variety of customised pipe forming connection solutions. HANSA-FLEX has a well-equipped production floor with machines and equipment capable of pipe forming to the customer’s specific requirements by swaging, rolling or combinations of different processes. HANSA-FLEX also has special soldering and welding expertise."}
        />



      </div>

      <SectionHeader
        title={"THE SPECIALISTS FOR PIPE-END PROCESSING AND CONNECTIONS"}
        subtitle={"HANSA-FLEX has the know-how and technology to implement many different requirements for pipe-end processing, including standard and special solutions as one-off items or in batch production."}
      />


      <div >

        <ImageText data={ProductPipebendimagetext1} left={false} />
      </div>

      <div >

        <ImageText data={ProductPipebendimagetext2} />
      </div>

      <div >

        <ImageText data={ProductPipebendimagetext3} left={false} />
      </div>

      <SectionHeader
        title={"PIPES PROCESSED PRECISELY TO MEET YOUR REQUIREMENTS"}
        subtitle={"As a leading manufacturer of components for the fluid technology sector, HANSA-FLEX has the know-how and production resources to make customised pipe-ends using a variety of processes."}
      />


      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>

      <SectionHeader
        title={"SPECIAL FITTINGS MANUFACTURED BY HANSA-FLEX"}
        subtitle={""}
      />

      <div >

        <ImageText data={ProductPipebendimagetext4} left={false} />
      </div>

      <div >

        <ImageText data={ProductPipebendimagetext5} />
      </div>

      <div >
        <ImageText data={ProductPipebendimagetext6} left={false} />
      </div>

      <SectionHeader
        title={"ALL CONNECTIONS FROM THE SAME SUPPLIER"}
        subtitle={"Customers can buy all commercially available connections from HANSA-FLEX. These include anything from the traditional cutting ring to various formed metal solutions, and even specially designed connections on request."}
      />
      <ImageText data={ProductPipebendimagetext7} />


      <SectionHeader
        title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
      />
      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgProductPipeendimagecard1} learnMoreLink="/hydraulic-hoses" title="HANSA-FLEX flange fittings are the ideal fittings for large" desc="HANSA-FLEX flange fittings are ideal for large diameters, high-pressure applications and restricted installation situations." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgProductPipeendimagecard2} learnMoreLink="/metal-hoses" title="Made-to-measure bent pipes" desc="Precision pipes in proven HANSA-FLEX quality are manufactured made-to-measure out of various materials in the HANSA-FLEX pipe bending centre." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgProductPipeendimagecard3} learnMoreLink="/Industrial-hoses" title="Connected by pipe fittings" desc="Transitions between various components are always a point for careful consideration in hydraulic systems. Pipe fittings from HANSA-FLEX create a reliable connection." />
          </Col>

        </Row>
      </Container>



    </div>
  )
}

export default PipeendProcessing