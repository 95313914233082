import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage';
import imgSeries_Centerspread from '../../../assets/Services/Production/Series Production/Series_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import ImageText from '../../../Components/ImageText/ImageText'
import imgSeries_Imagetext_01 from '../../../assets/Services/Production/Series Production/Series_Imagetext_01.jpg'
import imgSeries_Imagetext_02 from '../../../assets/Services/Production/Series Production/Series_Imagetext_02.jpg'
import imgSeries_Imagetext_03 from '../../../assets/Services/Production/Series Production/Series_Imagetext_03.jpg'
import imgSeries_Imagetext_04 from '../../../assets/Services/Production/Series Production/Series_Imagetext_04.jpg'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png'
import ImageCard from '../../../Components/ImageCard/ImageCard';
import imgSeries_Imagecard_01 from '../../../assets/Services/Production/Series Production/Series_Imagecard_01.jpg'
import imgSeries_Imagecard_02 from '../../../assets/Services/Production/Series Production/Series_Imagecard_02.jpg'
import imgSeries_Imagecard_03 from '../../../assets/Services/Production/Series Production/Series_Imagecard_03.jpg'
import { Container, Row, Col } from 'react-bootstrap';

const SeriesProduction = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;


    const Series_Imagetext_01 = {
        image: imgSeries_Imagetext_01,
        title: 'Setting up an EDI interface to HANSA-FLEX',
        description: ['As part of a long-term partnership, HANSA-FLEX offers customers with requirements such as complete supplier integration and closely integrated processes or very high data transfer volumes a connection to our EDI system. The complexity of programming EDI interfaces means that the service is available only to selected customers who, because of high sales volumes with HANSA-FLEX, expend a lot of resources on managing their goods procurement processes. When setting up the EDI, HANSA-FLEX can use standard formats for data transfer such as EDIFACT, ODETTE, VDA, X12 and XML as well as special solutions to meet your specific requirements.'],
    }

    const Series_Imagetext_02 = {
        image: imgSeries_Imagetext_02,
        title: 'Setting up of BMEcat by HANSA-FLEX',
        description: ['BMEcat is a standardised catalogue data exchange format in accordance with the guidelines of the Bundesverband Materialwirtschaft, Einkauf und Logistik e. V. The catalogue data are extracted from the product data system at HANSA-FLEX and integrated into your company’s system. HANSA-FLEX installs this technology for customers who wish to have digital product data and individualised requirements in their product catalogue. Another benefit is that you can continue to use your familiar purchasing portal from where you procure all special components and HANSA-FLEX delivers the hydraulic or pneumatic components you have ordered directly to you.'],
    }


    const Series_Imagetext_03 = {
        image: imgSeries_Imagetext_03,
        title: 'Digital integration in three phases',
        description: ['The implementation of an EDI can take a few days or several months, depending on its complexity. The installation process always takes place in three phases. During the first phase, the necessary parameters such as data format and version of the formatting standards are determined. Then there is an initial integration test and a very careful comparison of the master and material data sets between your company and HANSA-FLEX. In the test phase, we perform extensive tests, which involves interchange of test data. In some cases, corrections have to be made and the comparison of material data repeated. The integration is completed with the go-live phase including monitoring of the data flow.'],
    }


    const Series_Imagetext_04 = {
        image: imgSeries_Imagetext_04,
        description: 'Our series production facilities are designed so that we can supply traditional, medium-sized companies as well as industrial corporations as an OEM with pipelines on hose lines, ready to install and including customer-specific special fittings. Precisely synchronised processes, experienced staff and reliable quality inspections have already impressed many companies at home and abroad, including renowned manufacturers of rail transport and municipal vehicles, construction and agricultural machinery. We work with our customers to drive forward innovation and in this way improve the quality of the end product and the manufacturing parameters.'
    }

    const featuresData = [
        {
            title: "Made-to-measure manufacturing",
            description: "We preassemble hose lines and pipelines individually to meet the customer’s requirements",
            image: imgMark
        },
        {
            title: "High process reliability",
            description: "High process reliability allows us to achieve the proven HANSA-FLEX quality in series production.",
            image: imgMark
        },
        {
            title: "Delivery on schedule",
            description: "State-of-the-art manufacturing methods ensure rapid availability and delivery on time.",
            image: imgMark
        },
        {
            title: "Professional advice",
            description: "We advise you extensively and implement the optimum solution in accordance with your requirements.",
            image: imgMark
        }
        // Add more objects for additional FeaturesCard data
    ];





    return (
        <div>
            <CenterStage imageSrc={imgSeries_Centerspread} title={"SERIES PRODUCTION OF PIPELINES AND HOSE LINES"}
                paragraph={"Our central hose line series production facility in Bremen and the pipe bending centre in Schönebeck deliver tens of thousands of articles including ready-to-install high-pressure, PTFE and industrial hose lines, made-to-measure and in small or large batches, to OEMs every month. With us, you can expect excellent process reliability and compliance with quality standards. We also adapt our procedures in warehouse product management and logistics to suit your requirements."}
            />


            <SectionHeader
                title={"PRODUCTION IN FULL FLOW"}
                subtitle={"Processes perfectly synchronised for the optimum solution"}
            />

            <div >
                <br />
                <ImageText data={Series_Imagetext_01} left={false} />
            </div>

            <div >
                <br />
                <ImageText data={Series_Imagetext_02} />
            </div>

            <div >
                <br />
                <ImageText data={Series_Imagetext_03} left={false} />
            </div>

            <SectionHeader
                title={"SERIES PRODUCTION BENEFITS"}
                subtitle={"From the production floor to the dispatch department, our series production team provides an all-round service perfectly based on your needs."}
            />

            <div>
                <FeatureCardCarousel featuresData={featuresData} />
            </div>

            <SectionHeader
                title={"YOU CAN ALWAYS RELY ON US"}
                subtitle={"We supply you with high-quality hose lines and pipelines on schedule and in the quantities you wish."}
            />

            <div >
                <br />
                <ImageText data={Series_Imagetext_04} left={false} />
            </div>

            {/* TODO: Video_Videocenter*/}

            {/* <SectionHeader
                title={"OUR SERVICES PORTFOLIO IN DETAIL"}
                subtitle={""}
            /> */}

            {/* TODO: Redbanner_Carrousel */}




            <SectionHeader
                title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                subtitle={""}
            />

            <Container className="custom-container">
                <Row>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgSeries_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="A suitable hose for every application" desc="With us you can select from a wide range of high-quality hydraulic, industrial, preformed hoses, pressure and suction hoses manufactured from many different materials." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                        <ImageCard image={imgSeries_Imagecard_02} learnMoreLink="/metal-hoses" title="Hose fittings with zinc-nickel surface coating" desc="HANSA-FLEX hose fittings are perfectly matched to the hose type, so that a safe connection is guaranteed even at high pressures." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgSeries_Imagecard_03} learnMoreLink="/Industrial-hoses" title="Pipelines made-to-measure" desc="Bent pipelines from HANSA-FLEX are available in various materials, such as steel, stainless steel, copper or aluminium, and with different surface treatments." />
                    </Col>

                </Row>
            </Container>









        </div>
    )
}

export default SeriesProduction