import React from 'react';
import './TwoColumnPoints.css';
import { BsCheckLg } from "react-icons/bs";
import { Row, Col } from 'react-bootstrap';
import SectionHeader from '../SectionHeader/SectionHeader';
import { useTranslation } from 'react-i18next';

const TwoColumnPoints = ({ points, title, subtitle }) => {
    const { t } = useTranslation();

    // Split points into pairs
    const midPoint = Math.ceil(points.length / 2);
    const column1 = points.slice(0, midPoint);
    const column2 = points.slice(midPoint);

    return (
        <div className="points-container">
            <div className='points-container-header'>
                <SectionHeader title={t(title)} subtitle={t(subtitle)} />
            </div>
            {column1.map((point1, index) => (
                <Row key={index} className="points-row justify-content-center">
                    <Col md={6} className="points-column">
                        <div className="points-item">
                            <span ><BsCheckLg className="li-promoter" /></span>
                            <span>{t(point1)}</span>
                        </div>
                    </Col>
                    <Col md={6} className="points-column">
                        {column2[index] && (
                            <div className="points-item">
                                <span ><BsCheckLg className="li-promoter" /></span>
                                <span>{t(column2[index])}</span>
                            </div>
                        )}
                    </Col>
                </Row>
            ))}
        </div>
    );
};

export default TwoColumnPoints;
