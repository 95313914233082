import React from 'react';
import './TextCaption.css';
import { useTranslation } from 'react-i18next';

const TextCaption = ({ title, subtitle, paragraph, bulletPoints, numbered }) => {
  const { t } = useTranslation();
  return (
    <div className="text-caption-header">
      {title && <h1 className="text-caption-title" style={{ color: '#28588F' }}>{t(title)}</h1>}
      {subtitle && <p className="text-caption-subtitle">{t(subtitle)}</p>}
      {paragraph && <p className="text-caption-paragraph">{t(paragraph)}</p>}
      {bulletPoints && (
        <ul className={`bullet-point-list text-caption-bullets ${numbered ? 'numbered' : 'bullet'}`}>
          {bulletPoints.map((point, index) => (
            <li key={index}>{/* Using the Circle icon from React Icons */}
              {t(point)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TextCaption;
