import React, { useEffect } from 'react';
import CenterStage from '../../../../Components/CenterStage/CenterStage';
import HFTab from '../../../../Components/HfTabs/HfTabs';
import imgHoseManagement from '../../../../assets/Services/HoseManagement/hosemanagement.jpg';
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../../Components/ImageCard/ImageCard';
import Promoter from '../../../../Components/Promoter/Promoter';
import imgTabletMobile from '../../../../assets/HosesAndHoseLines/HosesAndHoseLine/tabletMobile.png'
import ImageText from '../../../../Components/ImageText/ImageText';
import FeatureCardCarousel from '../../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../../assets/mark.png';

import imgFulfillRequirement from '../../../../assets/Services/HoseManagement/fulfil_all_requirements.jpg'
import imgPlanInspection from '../../../../assets/Services/HoseManagement/plan_inspection.jpg'
import imgHoseCoding from '../../../../assets/Services/HoseManagement/x_code_hose_coding.jpg'
import imgHoseSoftware from '../../../../assets/Services/HoseManagement/hose_management_software.jpg'
import imgCertifiedTechnicians from '../../../../assets/Services/HoseManagement/certified_service_technicians.jpg'
import imgCoding from '../../../../assets/Services/HoseManagement/coding.jpg'
import imgInstalation from '../../../../assets/Services/HoseManagement/installation.jpg'
import imgServicingPlanning from '../../../../assets/Services/HoseManagement/servicingPlanning.jpg'
import imgDocumentation from '../../../../assets/Services/HoseManagement/documentation.jpg'
import imgHoseFixedCost from '../../../../assets/Services/HoseManagement/Hose_management_services_fixed_cose.jpg'
import imgProceduralRegulation from '../../../../assets/Services/HoseManagement/ProceduralRegulation.jpg'
import imgLegalRequirement from '../../../../assets/Services/HoseManagement/LegalRequirement.jpg'
import imginspection from '../../../../assets/Services/HoseManagement/inspection.jpg'

//PAGE COMPLETE

const HoseManagement = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    //ImageText Data
    const hoseManagement1 = {
        image: imgFulfillRequirement,
        title: 'Reliably fulfil all legal requirements',
        description: 'The employer is personally liable: How to handle hydraulic hose lines safely is precisely defined in the EU Use of Work Equipment Directive 2009/104/EC, the German Industrial Safety Act (BetrSichV) and other legislation. The employer is obliged to assess the potential risks from hydraulic hose lines and take suitable mitigating measures, carry out regular inspections and keep records. Hose management from HANSA-FLEX helps with these tasks.'
    }

    const hoseManagement2 = {
        image: imgPlanInspection,
        title: 'Plan inspections efficiently and perform them professionally',
        description: 'The unique marking, digital capture, efficient management and legally compliant documentation of all hose lines in use mean all the data are available and all it takes is a few mouse clicks. This information allows servicing to be efficiently planned, performed at the right time and recorded. HANSA-FLEX supports you in planning your processes and in their implementation as part of ordinary everyday activities – from providing an individual service measure right up to the all-round, carefree package.'
    }

    const hoseManagement3 = {
        image: imgHoseFixedCost,
        title: 'Hose management services at a fixed price',
        description: 'Our four service packages: Basic, Plus, Premium and Pro contain various services for your hose management. Individual packages build on one another and ensure maximum planning certainty for a fixed daily flat rate price. Use our hose management service package to simplify and improve the maintenance of your hydraulic systems. The range of the packages extends from free use of the X-CODE Manager software (Basic) to the data and digitalisation package (Plus) right up to the all-round carefree package including inspection and documentation in accordance with BetrSichV (Premium). We have also covered the extra portion service (Pro).',
        buttonName: "VIEW SERVICE PACKAGES"
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Better safety",
            description: "You reduce accidents and ensure safety at work for equipment users.",
            image: imgMark
        },
        {
            title: "Improved legal compliance",
            description: "You observe servicing intervals and record inspections in compliance with the law.",
            image: imgMark
        },
        {
            title: "Reduced downtime",
            description: "You avoid unplanned machine downtime and reduce your costs by avoiding component wear.",
            image: imgMark
        },
        {
            title: "More transparency",
            description: "You have access to all the information about your hose lines, machines and servicing dates at all times.",
            image: imgMark
        },
    ];

    //HfTab data 1
    const imageTextData = [
        {
            title: "Manufacturing, coding and digital profile",
            description: "We preassemble the hose line specifically for your application and give it the unmistakable X-CODE hose code. The X-CODE forms the basis for hose management and the creation of digital profiles containing all the relevant information. Existing hose lines without an X-CODE can be coded subsequently using follow-up coding by our Industrial Service so that they can be included in the hose management system.",
            image: imgCoding
        },
        {
            title: "Installation and digital assignment to machines",
            description: "HANSA-FLEX Industrial Service can perform the professional installation of your hose lines for you. The digital profile of each hose line is assigned in the X-CODE Manager hose management software directly to the relevant machine or component. You can depict all your machines and equipment in X-CODE Manager and structure the information according to your own criteria, so that you obtain the best overview of all hydraulic hose lines related to your locations, machines and components.",
            image: imgInstalation
        },
        {
            title: "Risk analysis and classification",
            description: "Unfortunately, we cannot perform your risk assessment for you but we can considerably assist you in preparing one. A risk analysis serves as the basis for the risk assessment of every hose line by the machine operator. The risk assessment determines the classification, the appropriate servicing plan and the time frames for the legally prescribed visual inspections.",
            image: imgServicingPlanning
        },
        {
            title: "Visual inspection and legally compliant documentation",
            description: "Legally prescribed visual inspections must be performed on every hose line by an officially authorised person for inspection and documented in compliance with the law. An inspection that has not been properly documented is deemed not to have taken place. We can perform all the inspections and documentation for you. One of the forms of documentation is a digital inspection record.",
            image: imgDocumentation
        },
    ];

    const tabTitles = [
        { title: "Coding", eventKey: "Coding" },
        { title: "Installation", eventKey: "Installation" },
        { title: "Servicing planning", eventKey: "Servicing planning" },
        { title: "Documentation", eventKey: "Documentation" },
    ];

    //HfTab data 2
    const imageTextData2 = [
        {
            title: "European and national legal requirements",
            description: "A host of European and national legislation governs the safe and legally compliant handling of hose lines. For example, the German Industrial Safety Act (BetrSichV) obliges employers to perform risk assessments for their work equipment, perform regular inspections and record the results.",
            image: imgLegalRequirement
        },
        {
            title: "Procedural regulations for employers",
            description: "National health and safety at work regulations and technical information, rules and standards make clear these duties and formulate practical procedural regulations for employers. These include proper use, systematic inspection and the planned replacement of hose lines.",
            image: imgProceduralRegulation
        },
        {
            title: "Inspection in accordance with BetrSichV and DGUV R",
            description: 'Only officially “authorised persons for inspection” may perform inspections of hose lines (§14 BetrSichV). The type and scope of inspections are defined by the risk assessment, while additional information can be found in DGUV Rule 113-020. By recording the inspection and its results, employers comply with the legal requirement to document the inspection (§6 ArbSchG (German Occupational Health and Safety Act) and §14 BetrSichV).',
            image: imginspection
        },
    ];

    const tabTitles2 = [
        { title: "Legal requirements", eventKey: "Legal requirements" },
        { title: "Procedural regulations", eventKey: "Procedural regulations" },
        { title: "Inspections", eventKey: "Inspections" },
    ];





    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgHoseManagement}
                    title={"KEEP YOUR HYDRAULICS OUT OF THE RED ZONE"}
                    paragraph={"Hydraulic hose lines are highly stressed items of work equipment. They must be regularly inspected and replaced at the right time to avoid risks to people and machinery. Professional hose management provides everything necessary to ensure these inspections take place in good time within the legally prescribed timeframe and are recorded."}
                />
            </div>

            <div>
                <SectionHeader
                    title={'HOSE MANAGEMENT MAKES THINGS EASIER FOR MAINTENANCE'}
                    subtitle={'Use your hydraulic hose lines for as long as is safe and sensible. With hose management you are always aware of the legal inspection dates of your work equipment and can plan and carry out your future inspections and servicing at the right time.'}
                />
                <ImageText
                    data={hoseManagement1} />
                <ImageText
                    data={hoseManagement2} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"360° HOSE MANAGEMENT FROM MANUFACTURE TO DISPOSAL"}
                />
                <HFTab imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <Promoter
                    title={"ORDER IDENTICAL HOSE ASSEMBLIES EVEN FASTER NOW"}
                    checklistItems={["The new HANSA-FLEX hose line configurator enables even faster and easier re-ordering of an identical hose line with just two clicks via the X-CODE."]}
                    buttonLabels={["CONFIGURE NOW", "LEARN MORE"]}
                    imageUrl={imgTabletMobile}
                />
            </div>

            <div>
                <SectionHeader
                    title={"SAFE AND COST-EFFECTIVE"}
                    subtitle={"Hose management pays dividends. Our services help you to operate your mobile and stationary hydraulic systems safely and in compliance with the law. You protect people and machinery while saving time and money. Rely on professional hose management from HANSA-FLEX and benefit from:"}
                />

                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <ImageText data={hoseManagement3} />
            </div>

            <div>
                <SectionHeader
                    title={"LEGISLATION GOVERNING HOW TO HANDLE HOSE LINES"}
                    subtitle={"Hose management services provided by HANSA-FLEX are completely based on the current European and national legislation legal requirements. The services will ensure you – as an employer, maintainer or operator – always keep on the safe side!"}
                />
                <HFTab imageTextData={imageTextData2} tabTitles={tabTitles2} />
            </div>

            <div>
                <SectionHeader
                    title={"CODING, SOFTWARE AND SERVICE TECHNICIANS ON SITE"}
                    subtitle={"The hose management services and service packages from HANSA-FLEX are based on three elements."}
                />

                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={imgHoseCoding}
                                title="X-CODE Hose Coding"
                                desc={"Utilize extensively tested coding with X-CODE to uniquely identify each hose line."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgHoseSoftware}
                                title="X-CODE Manager Hose Management Software"
                                desc={"Use the X-CODE Manager to get the best overview of your hose lines, machinery, and maintenance dates."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgCertifiedTechnicians}
                                title="Certified service technicians install and inspect on-site"
                                desc={"Have HANSA-FLEX's Industrial Service experts perform all tasks involved in installing, maintaining, and servicing your hose lines."} /></Col>
                        </Row>

                    </Container>
                </div>

            </div>

        </div>

    )
}

export default HoseManagement