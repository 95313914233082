import React, { useEffect } from 'react';

import Stage from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/Stage.jpeg'
import Dispence from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/Dispence.png'
import UnMistakable from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/UnMistakable.png'
import YouHaveNever from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/YouHaveNever.png'
import YourPersonalControl from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/YourPersonalControl.png'
import DivideYourMachine from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/DivideYourMachine.png'
import Depict from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/Depict.png'
import FindEverything from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/FindEverything.png'
import Inspect from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/Inspect.png'
import Legally from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/Legally.png'
import FourRoles from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/FourRoles.png'
import UseApp from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/UseApp.png'
import SuitableSolution from '../../../../../assets/Services/PreventativeMaintainance/XCodeManager/SuitableSolution.png'


import CenterStage from '../../../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../../../Components/SectionHeader/SectionHeader';
import ImageText from '../../../../../Components/ImageText/ImageText';
import imgMark from '../../../../../assets/mark.png'
import FeatureCardCarousel from '../../../../../Components/FeaturesCardCarousal/FeatureCardCarousal';

const XCodeManager = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const data = {
        image: Dispence,
        description: 'X-CODE Manager can do more for you than just act as a spreadsheet. The browser-based software solution was specially developed at HANSA-FLEX for maintenance and offers you all the important functionalities for managing your hydraulic hose lines. You can use X-CODE Manager in its desktop version or as a mobile app. Benefit from an individual digital profile with all the relevant information for every single hose line. Depict your machinery and equipment in your own folder structure and capture the data off-line directly at the machine. Perform the regular visual inspections in accordance with DGUV and BetrSichV yourself with the help of the inspection module and manage the digital inspection records at a central location. You will find there are many more practical functions for you to use in your hose management processes.',
    }

    const featuresData = [
        {
            title: "Every detail",
            description: "Collect all the information about your hose lines digitally in one location.",
            image: imgMark
        },
        {
            title: "Every machine",
            description: "Organise locations, machines and components in customised folder structures.",
            image: imgMark
        },
        {
            title: "Every scheduled date",
            description: "Organise all your servicing and inspection intervals in practical overviews.",
            image: imgMark
        },
        {
            title: "Every legal requirement",
            description: "Benefit when performing visual inspections in accordance with BetrSichV in our legally compliant inspection module.",
            image: imgMark
        }
    ];


    const data1 = {
        image: UnMistakable,
        title: 'Unmistakable and error-free. Proven millions of times.',
        description: "Hose identification with X-CODE forms the basis of your preventive maintenance in hydraulics. The X-CODE is a unique alphanumeric code representing all the features of your hydraulic hose line and, in future, your filters, pressure accumulators and other components. X-CODE Manager stores all the X-CODEs for your components and all the relevant data and functionality for maintenance and ordering replacement parts.",
    }

    const data2 = {
        image: YouHaveNever,
        title: 'You have never seen your hose lines in quite this way.',
        description: "X-CODE Manager offers you many options for quick and easy management of your hydraulic hose lines. Combine all the relevant features for each individual hose line in a customised digital profile and add information such as machine assignment, inspection dates and records. Organise your preventive maintenance exactly how you would like to and according to your needs. Structure your customised database precisely as you require.",
    }

    const data3 = {
        image: YourPersonalControl,
        title: 'Your personal control centre for maintenance',
        description: "The clearly laid-out start page is a good place to begin working with X-CODE Manager. Find your way intuitively into the various areas to quickly access the information you want. Obtain the very latest overview updated daily of all your hose lines, machines and servicing dates. Organise all the relevant information about the legally prescribed inspections and legally compliant documentation.",
    }

    const data4 = {
        image: DivideYourMachine,
        title: 'Divide your machines into practical units for servicing purposes.',
        description: "Use the machines part of the software to efficiently and conveniently manage your whole collection of machines and equipment. Subdivide your machines into various components to your preferred level of detail. Allocate each hose line accurately to your components to optimise the planning of your servicing dates.",
    }

    const data5 = {
        image: Depict,
        title: 'Depict the structure of your operations in the software exactly as it is in reality.',
        description: "Organise your maintenance dates in clearly arranged, self-defined folders. Sort your information to the level of detail you wish, for example, according to location, department, production facility, machine or item of equipment. Depict the structure of your organisation as you wish in your customised X-CODE Manager database.",
    }

    const data6 = {
        image: FindEverything,
        title: 'Find everything quickly, every time',
        description: "Use the convenient live search across all areas to quickly and directly find all the information you require, such as X-CODEs, folders, machines or dates. Search through your X-CODE overview for the features you are interested in such as inventory numbers, cost centres or document numbers and use context searches in the filtered results. Search dependably through individual fragments of results to obtain all the information about defective or incomplete X-CODEs.",
    }

    const data7 = {
        image: Inspect,
        title: 'Inspecting and reordering at the right time do not happen by accident.',
        description: "Optimising your servicing planning based on the legally prescribed risk assessment and classification for each individual hose line. Plan and record your future servicing and maintenance dates. Look ahead and order the required replacement hose lines with just a few mouse clicks.",
    }

    const data8 = {
        image: Legally,
        title: 'Legally compliant inspection in accordance with BetrSichV and DGUV.',
        description: "Use the inspection module in the EXPERT licence to conduct recurring visual inspections in accordance with BetrSichV and DGUV in-house without external support. The software guides officially authorised persons for hydraulic line technology step-by-step through the inspection, enabling convenient and direct input of the data and thus avoiding later transcription errors. The inspection module is continually updated so that you are always informed of the latest situation with regard to legal requirements.(EXPERT licence and above)",
    }

    const data9 = {
        image: FourRoles,
        title: 'Four roles for every instance.',
        description: "Define suitable user permissions concepts for your data and use these as the basis for optimising your processes and sequences of hydraulic equipment maintenance. Assign your users to one of the four roles with different user permissions: from read-only to full administration. Define individual read permissions for each user and individual computer.(EXPERT licence and above)",
    }

    const data10 = {
        image: UseApp,
        title: 'Use the app any time on the road and off-line.',
        description: "Use X-CODE Manager with the FLEXIBLE licence with your tablet or smartphone directly at the machine. Edit your data in the practical off-line mode if you have no access to the Internet. This avoids you having to laboriously write down the codes and any transcription errors.(FLEXIBLE licence and above)",
    }


    const data11 = {
        image: SuitableSolution,
        description: 'You can use X-CODE Manager in various licence packages as part of a software as a service agreement. Your HANSA-FLEX customer adviser is always happy to help you with the choice of the most suitable solution for your company. Contact us by email at my@HANSA-FLEX.de or call us on +49 (0)421 48907 766 if you do not yet have a named contact partner at HANSA-FLEX. One of our customer advisers will contact you as quickly as possible. You would like to authorise more users than shown above for X-CODE Manager? You can add as many add-ons, each for 10 additional users, as you like.',
    }


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"GIVE YOURSELF THE BEST OVERVIEW. FROM 0.00 EURO PER MONTH."}
                    paragraph={"X-CODE Manager in the My.HANSA-FLEX customer portal is the convenient software solution for the management of your hydraulic systems. Create and manage your customised database with all the relevant information about your hydraulic hose lines in X-CODE Manager. The software helps you to operate your systems safely and in conformity with the law at all times. Benefit from the best overview of machines, components and scheduled dates, while optimising the future planning of your servicing tasks."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"DISPENSE WITH DIY SPREADSHEET TABLES"}
                />
                <ImageText
                    data={data} />
            </div>

            <div>
                <SectionHeader
                    title={"ONLY X-CODE MANAGER CAN DO ALL THIS"}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"FIND OUT MORE ABOUT X-CODE MANAGER"}
                />
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
                <ImageText
                    data={data3} />
                <ImageText
                    data={data4} left={false} />
                <ImageText
                    data={data5} />
                <ImageText
                    data={data6} left={false} />
                <ImageText
                    data={data7} />
                <ImageText
                    data={data8} left={false} />
                <ImageText
                    data={data9} />
                <ImageText
                    data={data10} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"WE ARE HAPPY TO DO EVEN MORE FOR YOU"}
                    subtitle={"Hose management service package with numerous services for preventive maintenance and servicing your systems."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"A SUITABLE SOLUTION FOR EVERY COMPANY"}
                />
                <ImageText
                    data={data11} />
            </div>
        </div>
    )
}

export default XCodeManager