import React from 'react';

function HansaFlexGroupPage() {
  return (
    <div>
      <h1>HANSA-FLEX Group</h1>
      <p>Discover more about the HANSA-FLEX Group and our global presence.</p>
    </div>
  );
}

export default HansaFlexGroupPage;