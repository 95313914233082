import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import Assembly_Centerspread from '../../../assets/Services/Production/Customised Assembly/Assembly_Centerspread.jpg'
import ImageText from '../../../Components/ImageText/ImageText';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook;
import imgAssembly_Imagetext_01 from '../../../assets/Services/Production/Customised Assembly/Assembly_Imagetext_01.jpg'
import imgAssembly_Imagetext_02 from '../../../assets/Services/Production/Customised Assembly/Assembly_Imagetext_02.jpg'
import imgAssembly_Imagetext_03 from '../../../assets/Services/Production/Customised Assembly/Assembly_Imagetext_03.jpg'
import imgAssembly_Imagetext_04 from '../../../assets/Services/Production/Customised Assembly/Assembly_Imagetext_04.jpg'
import imgAssembly_Imagetext_05 from '../../../assets/Services/Production/Customised Assembly/Assembly_Imagetext_05.jpg'
import imgAssembly_Imagetext_06 from '../../../assets/Services/Production/Customised Assembly//Assembly_Imagetext_06.jpg'
import ImageCard from '../../../Components/ImageCard/ImageCard';
import imgAssembly_Imagecard_01 from '../../../assets/Services/Production/Customised Assembly/Assembly_Imagecard_01.jpg';
import imgAssembly_Imagecard_02 from '../../../assets/Services/Production/Customised Assembly/Assembly_Imagecard_02.jpg';
import imgAssembly_Imagecard_03 from '../../../assets/Services/Production/Customised Assembly/Assembly_Imagecard_03.jpg';
import { Container, Row, Col } from 'react-bootstrap';

import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';




const CustomisedAssembly = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;

    const Assembly_Imagetext_01 = {
        image: imgAssembly_Imagetext_01,
        title: 'Advice and manufacture by experienced technicians',
        description: ['The preassembly and inspection of hydraulic hose lines are performed only by trained employees. They assemble suitable components for every proposed use and connect them properly together. Our technical employees as experienced contact partners see giving customer advice as part of their job and have undergone comprehensive training. In addition, officially authorised persons for whose technology inspect your machines and systems on site and assess whether and which hose lines need to be replaced. They also advise you on how to optimise your hose arrangements.'],
    }

    const Assembly_Imagetext_02 = {
        image: imgAssembly_Imagetext_02,
        title: 'Professional preassembly in the HANSA-FLEX branch',
        description: ['Each of our branches has its own hose workshop to ensure faultless manufacture of hydraulic hose lines. Here our experienced technicians cut, peel and crimp hose products to form ready-to-install hose lines. With access to top-class machines and innovative hose technology, such as hose rollers, cutters, peelers, crimpers, devices for cutting to length, projectile cleaning, test mandrels and pressure test benches, our technicians manufacture high-performance hose lines of various sizes in their workshops to consistently high quality standards.'],
    }

    const Assembly_Imagetext_03 = {
        image: imgAssembly_Imagetext_03,
        title: 'Preassembly of combined hose-pipelines',
        listItems: ['Implementation of flexible line geometries', 'Advice for planning of hydraulic systems and machines', 'High-quality processing using certified welding procedures'],
        description: ['In addition to hydraulic hose lines, HANSA-FLEX also manufactures combined hose-pipelines for a wide range of fluid technology applications. The experienced specialists use their comprehensive knowledge to advise you on the appropriate design and give helpful tips about correct installation.'],
    }

    const Assembly_Imagetext_04 = {
        image: imgAssembly_Imagetext_04,
        title: 'Preassembly of metal hose lines',
        listItems: ['Certified jointing processes, 100 % tested for leaks', 'Nominal diameters from DN 6 to DN 250 mm', 'Free of oil, grease and LABS, depending on requirements'],
        description: ['We preassemble robust metal hose lines from high-quality corrugated hoses. Welding in accordance with DIN EN ISO 3834-2 guarantees a safe connection even under extreme conditions.'],
    }

    const Assembly_Imagetext_05 = {
        image: imgAssembly_Imagetext_05,
        title: 'Preassembly of metal hose lines',
        listItems: ['Manufactured in accordance with the Pressure Equipment Directive, DGUV and T 002', 'Preassembly including accessories and connection systems', 'Suction and delivery hose line manufacture'],
        description: ['We preassemble robust metal hose lines from high-quality corrugated hoses. Welding in accordance with DIN EN ISO 3834-2 guarantees a safe connection even under extreme conditions.'],
    }

    const Assembly_Imagetext_06 = {
        image: imgAssembly_Imagetext_06,
        title: 'Preassembly of metal hose lines',
        listItems: ['Manufactured in accordance with the Pressure Equipment Directive, DGUV and T 002', 'Preassembly including accessories and connection systems', 'Suction and delivery hose line manufacture'],
        description: ['We preassemble robust metal hose lines from high-quality corrugated hoses. Welding in accordance with DIN EN ISO 3834-2 guarantees a safe connection even under extreme conditions.'],
    }





    const featuresData = [
        {
            title: "Experienced technicians",
            description: "Every hose is assembled and inspected by qualified personnel",
            image: imgMark
        },
        {
            title: "Comprehensive support",
            description: "We discuss your requirements and any potential for optimisation before manufacturing the hose",
            image: imgMark
        },
        {
            title: "Extensive warehouse stock",
            description: "Extensive stocks of common fittings and hoses ensure rapid completion",
            image: imgMark
        },
        {
            title: "Quick delivery",
            description: "We start work on your requirements without delay. You receive the finished hose line soon after completion",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];





    return (
        <div>
            <div >

                <CenterStage imageSrc={Assembly_Centerspread} title={"PREASSEMBLED HOSE LINES, READY TO INSTALL"}
                    paragraph={"We preassemble customised hydraulic hose lines from samples and to suit customer specific requirements for low, medium and high-pressure applications with the associated fittings in our branches. Our hose specialists always keep the safety of people and the environment in mind when selecting the materials and the method of joining the hose to the fitting. Whatever your need, we can advise you and manufacture a suitable hose line."}
                />

                <SectionHeader
                    title={"A SYSTEM SOLUTION TAILORED TO YOUR REQUIREMENTS"}
                    subtitle={"The highest quality standards apply to our preassembled hose lines. The result is a leak-proof positive connection between hose and fitting, guaranteeing safety in use even at high pressures."}
                />

                <br />
                <ImageText data={Assembly_Imagetext_01} left={false} />
            </div>

            <div >
                <br />
                <ImageText data={Assembly_Imagetext_02} />
            </div>

            {/* TODO: Redband_Sectionheader */}

            {/* <SectionHeader
                title={"PROFESSIONAL PREASSEMBLY GUARANTEED"}
                subtitle={"Preassembling customised hose lines starts with cutting the hydraulic hose to the required length, then crimping and marking and finally an optional pressure test."}
            /> */}

            {/* TODO: Redbanner_Carrousel */}

            <SectionHeader
                title={"HOSES PREASSEMBLED IN YOUR LOCALITY"}
                subtitle={"As a fluid technology specialist with many years’ experience, HANSA-FLEX offers the highest degree of flexibility, safety, durability and cost efficiency in hose line manufacture directly in its local branches."}
            />

            <div>
                <FeatureCardCarousel featuresData={featuresData} />
            </div>

            {/* <SectionHeader
                title={"More benefits for you"}
            listItems=["High-quality components from HANSA-FLEX and renowned manufacturers", 'High-quality components from HANSA-FLEX and renowned manufacturers', 'Personal service from our technicians on site', 'HANSA-FLEX is your guarantee of professional preassembly', 'The perfect combination of fitting and hose']
            /> */}
            {/* TODO: Points */}


            <SectionHeader
                title={"PREASSEMBLY OF NUMEROUS HOSE TYPES"}
                subtitle={"In addition to hydraulic hose lines, we also manufacture hose lines for numerous other fields of application such as the transport of hot or abrasive media or for use in the food industry."}
            />

            <div >
                <br />
                <ImageText data={Assembly_Imagetext_03} left={false} />
            </div>

            <div >
                <br />
                <ImageText data={Assembly_Imagetext_04} />
            </div>

            <div >
                <br />
                <ImageText data={Assembly_Imagetext_05} left={false} />
            </div>


            {/* <SectionHeader
                title={"SERVICE WITH ADDED VALUE"}
                subtitle={"As a fluid technology specialist with many years’ experience, HANSA-FLEX offers the highest degree of flexibility, safety, durability and cost efficiency in hose line manufacture directly in its local branches."}
            /> */}

            {/* TODO: Redbanner_Carrousel */}

            <SectionHeader
                title={"NEW AND REPLACEMENT HOSES ARE MANUFACTURED IN THE LOCAL HANSA-FLEX"}
                subtitle={"Our technicians advise you as your direct contact partner in our local branches and work closely with you to create the perfect hose solution for your specific application."}
                listItems={'Sealing of hose ends, with plastic capsor by wrapping in transparent film'}
            />



            <div >
                <br />
                <ImageText data={Assembly_Imagetext_06} />
            </div>

            {/* <SectionHeader
                title={"HOSE-RELATED SERVICES"}
                subtitle={"As well as manufacturing hose lines in series production facilities, we also offer various solutions for manufacturing customised, preassembled solutions for you directly on site. In addition, our experienced fluid experts can advise you with hydraulic."}
            />  */}



            {/* TODO: imagetext_carrousel */}


            <SectionHeader
                title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                subtitle={""}
            />


            <Container className="custom-container">
                <Row>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgAssembly_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="Plant-in-plant manufacturing" desc="With plant-in-plant manufacturing, you benefit from immediately available materials and components. Our processes are precisely integrated into your workflows." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
                        <ImageCard image={imgAssembly_Imagecard_02} learnMoreLink="/metal-hoses" title="Hose lines in a kit" desc="With our precisely configured hose line kits, you save time on installation and can concentrate completely on your production processes." />
                    </Col>
                    <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
                        <ImageCard image={imgAssembly_Imagecard_03} learnMoreLink="/Industrial-hoses" title="Delivery following the kanban principle" desc="HANSA-FLEX offers kanban delivery as part of an optimally configured, customised logistics system." />
                    </Col>

                </Row>
            </Container>












        </div>

    )
}

export default CustomisedAssembly