import React, { useEffect } from 'react'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgWarehouse_Centerspread from '../../../assets/Services/Logistics solutions/Warehouse management/Warehouse_Centerspread.jpg'
import ImageText from '../../../Components/ImageText/ImageText'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import imgWarehouse_Imagetext_01 from '../../../assets/Services/Logistics solutions/Warehouse management/Warehouse_Imagetext_01.jpg'
import imgWarehouse_Imagetext_02 from '../../../assets/Services/Logistics solutions/Warehouse management/Warehouse_Imagetext_02.jpg';
import imgWarehouse_Imagetext_03 from '../../../assets/Services/Logistics solutions/Warehouse management/Warehouse_Imagetext_03.jpg';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import ImageCard from '../../../Components/ImageCard/ImageCard';
import { Container, Row, Col } from 'react-bootstrap';
import imgWarehouse_Imagecard_01 from '../../../assets/Services/Logistics solutions/Warehouse management/Warehouse_Imagecard_01.jpg'
import imgWarehouse_Imagecard_02 from '../../../assets/Services/Logistics solutions/Warehouse management/Warehouse_Imagecard_02.jpg'
import imgWarehouse_Imagecard_03 from '../../../assets/Services/Logistics solutions/Warehouse management/Warehouse_Imagecard_03.jpg'



const Warehousemanagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;

  const Warehouse_Imagetext_01 = {
    image: imgWarehouse_Imagetext_01,
    title: 'Optimising your inventory',
    description: ['We create and maintain a standardised article list for you. The list forms the basis for efficient warehouse management. The article list defines the ongoing use of material and enables the efficient use of your storage areas. We identify all the material groups you require to ensure a smooth production flow. As a one-stop provider, we can reliably supply you with the defined standard material. This allows you to consolidate your supplier list and save administration costs when ordering goods. With HANSA-FLEX, you can choose flexible delivery intervals to suit your developing needs.'],
  }

  const Warehouse_Imagetext_02 = {
    image: imgWarehouse_Imagetext_02,
    title: 'Proper storage of components',
    description: ['Component should be stored so that their quality and shelf-life are not adversely affected by dust, liquids or large temperature fluctuations. The risk of mechanical damage to components due to objects falling or rolling onto them should be minimised. Our highly capable employees are always available to provide advice and practical assistance to support you with their vast practice-proven knowledge and skills. These include, for example, the systematic positioning of warehousing systems and goods to shorten the distances your employees have to walk and the time they spend searching for the required items. This ensures there is nothing left to interfere with accessibility to components and machines.'],
  }


  const Warehouse_Imagetext_03 = {
    image: imgWarehouse_Imagetext_03,
    title: 'Solutions for high goods throughput',
    description: ['Larger companies require correspondingly more parts and having automated ordering and warehousing processes becomes more important. There comes a point when written recording of material requirements and manual entry of orders takes up too much time for the whole process to remain efficient. Speak with your usual HANSA-FLEX customer adviser about your options for digitalising your ordering processes, for example with a scanner solution, or automating them with a kanban system. Another possible way of optimising a high goods throughput would be to adopt a kit approach to your stock of parts.'],
  }



  const featuresData = [
    {
      title: "Reduced storage and material costs",
      description: "Efficient warehouse management can minimise your tied-up capital and scrapped parts.",
      image: imgMark
    },
    {
      title: "Substantial time savings",
      description: "Shorter walking distances, reduced administration costs for orders and no wasted time searching, thanks to systematic optimisation.",
      image: imgMark
    },
    {
      title: "Everything from a single source",
      description: "HANSA-FLEX is your reliable one-stop shop for all required components.",
      image: imgMark
    },
    {
      title: "Digital solutions",
      description: "We prepare the way and provide the equipment for your digitalised warehouse.",
      image: imgMark
    }
    // Add more objects for additional FeaturesCard data
  ];








  return (
    <div>

      <CenterStage
        imageSrc={imgWarehouse_Centerspread}
        title={"OPTIMAL WAREHOUSE MANAGEMENT WITH HANSA-FLEX"}
        paragraph={"As your system partner for everything to do with fluid technology, our goal is to reduce the time you spend on routine business activities, so that you can concentrate on value-creating activities. Our services include supporting you in systematically optimising your warehousing processes to reduce unnecessary time spent searching for items and to design a more efficient material flow. Working with you, we find the solution that best matches your requirements."}
      />



      <SectionHeader
        title={"EFFICIENT ORDERING AND WAREHOUSING PROCESSES"}
        subtitle={"We are not only a reliable supplier of components, we also support you with our experience and specialist knowledge, always with the aim of increasing the profitability of your ordering and warehousing processes."}
      />




      <div >
        <br />
        <ImageText data={Warehouse_Imagetext_01} left={false} />
      </div>

      <div >
        <br />
        <ImageText data={Warehouse_Imagetext_02} />
      </div>

      <div >
        <br />
        <ImageText data={Warehouse_Imagetext_03} left={false} />
      </div>


      <SectionHeader
        title={"CUSTOMER-FOCUSED WAREHOUSE MANAGEMENT WITH HANSA-FLEX"}
        subtitle={"We guarantee the availability of goods for you and offer you proactive support to design the associated processes to be as convenient and profitable as possible for you."}
      />


      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>



      <SectionHeader
        title={"THE PERFECT SERVICE PACKAGE FOR EVERY COMPANY"}
        subtitle={"HANSA-FLEX offers an expansive range of services, all flexibly adjustable to your requirements so that the overall solution is perfect for your company."}
      />

      <SectionHeader
        title={"OTHER SERVICES FOR WAREHOUSING AND LOGISTICS"}
        subtitle={""}
      />

      {/* TODO: Redbanner_Carrousel */}




      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgWarehouse_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="Scanner solutions for digital demand management" desc="Order your components for fluid technology directly in your warehouse or at your production line quickly and easily by scanner and HANSA-FLEX’s Easy Order app." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgWarehouse_Imagecard_02} learnMoreLink="/metal-hoses" title="Efficient manufacturing processes with parts supplied in kits" desc="Ready-to-install assembly kits of parts minimise initial costs, offer maximum quality and contribute to sustained increases in productivity." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgWarehouse_Imagecard_03} learnMoreLink="/Industrial-hoses" title="Kanban delivery from HANSA-FLEX" desc="With kanban delivery based on the pull principle from HANSA‑FLEX, you gain from Just-In-Time delivery, precisely when you need it, including directly into your" />
          </Col>

        </Row>
      </Container>












    </div>
  )
}

export default Warehousemanagement