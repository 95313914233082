import Carousel from 'react-bootstrap/Carousel';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import './ShowcaseCarousel.css';
import { useTranslation } from 'react-i18next';

function ShowcaseCarousel({ carouselItems, isExploreButton = true, descDisplay = 'block', carouselType = 1 }) {
  const { t } = useTranslation();
  const isMobile = window.innerWidth <= 768;


  var carouselPadding = '';
  var textCaptionMarginL = '';

  if (carouselType === 1) {
    if (isMobile) {
      carouselPadding = '0 0%';
      textCaptionMarginL = '0';

    } else {
      carouselPadding = '0 5%';
      textCaptionMarginL = '40%';
    }

  } else {
    if (isMobile) {
      carouselPadding = '0 0%';
      textCaptionMarginL = '0';

    } else {
      carouselPadding = '0 20%';
      textCaptionMarginL = '25%';
    }
  }


  return (
    <Carousel fade interval={2000} className='showcase-carousel'>
      {carouselItems.map((item, index) => (
        <Carousel.Item key={index} className='item-sh-car' style={{ height: '550px', padding: carouselPadding }}>
          <img
            className="d-block w-100 sh-carousel-image"
            src={item.imgSrc}
            alt={t(item.title)}
          />
          <Carousel.Caption className="text-caption-div" style={{ marginLeft: textCaptionMarginL }} >
            <h3 className="title-sh-car">{t(item.title)}</h3>
            <p className="subtitle-sh-car" style={{ display: descDisplay }} >{t(item.description)}</p>
            {isExploreButton ? (
              <div className='btn-explore-div'>
                <Link to={item.link} className="learn-more-link-sh-car">
                  {t("Explore")} <BsArrowRight className="arrow-icon" />
                </Link>
              </div>
            ) : null}
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default ShowcaseCarousel;
