import React from 'react';

function ProductsPage() {
  return (
    <div>
      <h1>Our Products</h1>
      <p>Here you can find information about our wide range of products.</p>
    </div>
  );
}

export default ProductsPage;