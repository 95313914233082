import React, { useEffect } from 'react';
import ImageDescription from '../../Components/ImageDescription/ImageDescription';
import imgProdTraining from '../../assets/news-events/prodTraning.jpeg';

function ProductTraining() {


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);
    return (
        <div>
            <ImageDescription
                imageSrc={imgProdTraining}
                paragraphs={[
                    "In a comprehensive product training session led by Gary Howes, participants delved into the intricacies of hose assemblies and related components. Many new things like understanding the critical role of hose assemblies in various applications and exploring different types of hoses and their practical uses was known."
                    , "He also emphasized step-by-step overview, including cutting, measuring, crimping, swaging, how to take safety precautions during welding and prioritizing safety in all aspects. Gary Howes’ informative session contributes to enhancing industry knowledge and promoting best practices."
                ]}
                title={"Product training session taken by Gary Howes"}
            />
        </div>
    );
}

export default ProductTraining;