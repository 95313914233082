import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import ImageText from '../../../Components/ImageText/ImageText'
import imgMark from '../../../assets/mark.png';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import { Col, Container, Row } from 'react-bootstrap';

import imgStage from '../../../assets/Products/DriveAndControlTechnology/PressureAccumulator/Stage.jpg'
import BladderAccumulator from '../../../assets/Products/DriveAndControlTechnology/PressureAccumulator/BladderAccumulator.jpg'
import RobustDipharm from '../../../assets/Products/DriveAndControlTechnology/PressureAccumulator/RobustDipharm.jpg'
import RequiredEquipment from '../../../assets/Products/DriveAndControlTechnology/PressureAccumulator/RequiredEquipment.jpg'
import PressureAccumulator from '../../../assets/Products/DriveAndControlTechnology/PressureAccumulator/PressureAccumulator.jpg'
import HowItWork from '../../../assets/Products/DriveAndControlTechnology/PressureAccumulator/HowItWork.jpg'
import Inspection from '../../../assets/Products/DriveAndControlTechnology/PressureAccumulator/Inspection.jpg'
import Repair from '../../../assets/Products/DriveAndControlTechnology/PressureAccumulator/Repair.jpg'
import Stock from '../../../assets/Products/DriveAndControlTechnology/PressureAccumulator/Stock.jpg'


const PressureAccumulators = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const Accumulator1 = {
        image: BladderAccumulator,
        title: 'Bladder accumulators for large oil volumes',
        description: 'Bladder accumulators consist of a high-strength pressure vessel and a sealed bladder, which can be filled with gas. These accumulators are notable for their high speed of reaction and rapid return of fluid to the system.',
        listItems: ["1 l to 50 l oil volume", "Permitted working pressure up to 350 bar", "Fluctuations are quickly equalised"],
        buttonName: "ALL BLADDER ACCUMULATORS"
    }

    const Accumulator2 = {
        image: RobustDipharm,
        title: 'Robust diaphragm accumulators',
        description: 'Diaphragm accumulators consist of a spherical pressure vessel and are robust against external forces. Diaphragm accumulators are used for applications with smaller volumes of oil.',
        listItems: ["0.075 l to 1.4 l oil volume", "Working pressure 100 to 350 bar", "Low-temperature application version for use down to - 40 °C available"],
        buttonName: "ALL DIAPHRAGM ACCUMULATORS"
    }

    const Accumulator3 = {
        image: RequiredEquipment,
        title: 'Required equipment and suitable accessories',
        description: 'The HANSA-FLEX online shop has numerous products, such as adapters, nuts and repair kits, for the mounting and safe operation of your pressure accumulator.',
        listItems: ["Safety and isolation blocks for making safe and depressurising", "Accumulator brackets and clamps for mounting", "Inspection and filling equipment for checking the nitrogen pressure"],
        buttonName: "ACCESSORIES"
    }

    const Accumulator4 = {
        image: PressureAccumulator,
        listItems: ["Permissible temperature for bladder accumulators: - 15 ℃ to + 80 ℃ ", "Permissible temperature for diaphragm accumulators: - 40 °C to + 80 °C", "Material for bladder and diaphragm: NBR standard grade", "Material for diaphragm low-temperature version: ECO", "Connections: Internal or external threads"],
    }

    const Accumulator5 = {
        image: HowItWork,
        title: 'How a pressure accumulator works',
        description: 'Pressure accumulators use the compressibility of nitrogen to absorb and store energy. An expanding elastomeric diaphragm or bladder divides the vessel into separate volumes for gas and oil. The gas side of the hydraulic accumulator is pressurised with gas at the required pressure before the system starts operation. If the fluid side of the system become subject to increasing pressure, the pressures in the two sides are equalised by the movement of the separating component and compression of the gas. The result is equilibrium between the gas and fluid pressure, and fluid is admitted into the accumulator. As soon as the hydraulic pressure drops, the compressed gas expands and pushes the fluid back into the hydraulic circuit.',
    }

    // features card carousel data
    const featuresData = [
        {
            title: "High quality",
            description: "All pressure accumulators are manufactured by renowned European companies.",
            image: imgMark
        },
        {
            title: "No compromise on safety",
            description: "Pressure accumulators meet with the requirements of the European Pressure Equipment Directive (PED).",
            image: imgMark
        },
        {
            title: "Certified products",
            description: "Supplied with the appropriate European or international approval certificates.",
            image: imgMark
        },
        {
            title: "Advice from experts",
            description: "We support you in the selection and design of accumulators suitable for your application",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"HYDRAULIC ACCUMULATORS ARE PRESSURE COMPENSATION VESSELS AND ENERGY BUFFERS"}
                    paragraph={"Pressure accumulators are used as pressure compensation vessels and balance temperature-related changes in volume and pressure peaks in hydraulic systems. Acting as a buffer accumulator, they also absorb the energy caused by pressure changes and release it later when necessary. They are also used to safely compensate for leaks , dampen pulsations and absorb shocks in systems and machines."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"GAS-FILLED PRESSURE ACCUMULATORS FROM HANSA-FLEX"}
                    subtitle={"The HANSA-FLEX range includes numerous diaphragm and bladder accumulators in various designs. We can also supply you with other accumulated types, such as piston accumulators or individually manufactured special accumulators on request."}
                />
                <ImageText
                    data={Accumulator1} />
                <ImageText
                    data={Accumulator2}
                    left={false} />
                <ImageText
                    data={Accumulator3} />
            </div>

            <div>
                <SectionHeader
                    title={"THE BENEFITS OF USING OIL-HYDRAULIC ACCUMULATORS FROM HANSA-FLEX"}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"HIGH-QUALITY PRESSURE ACCUMULATORS FROM THE FLUID SPECIALISTS"}
                    subtitle={"All pressure accumulators are delivered ready to install from stock. On request, we can install your accumulator and fill it with nitrogen on site."}
                />
                <ImageText
                    data={Accumulator4} />
                <ImageText
                    data={Accumulator5} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"TRAINING AND SEMINARS ON PRESSURE ACCUMULATORS IN HYDRAULIC SYSTEMS"}
                    subtitle={"In cooperation with the International Hydraulics Academy (IHA), HANSA-FLEX runs seminars for installation engineers and pressure accumulator maintenance personnel. Experts pass on their specialist knowledge of the safe operation, proper filling and repair of pressure accumulators."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"THESE PRODUCTS AND SERVICES MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={Inspection}
                                title="Inspection of your pressure accumulator by an officially authorised person"
                                desc={"The fluid experts from HANSA-FLEX are officially authorised persons for pressure equipment. They carry out external visual inspections and provide inspection reports."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Repair}
                                title="Gas-filled pressure accumulators from HANSA-FLEX"
                                desc={"The HANSA-FLEX range includes numerous high-quality diaphragm and bladder accumulators of various designs from renowned manufacturers."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={Stock}
                                title="Prevent wear"
                                desc={"The cleanliness of the hydraulic fluid affects the service life of components. Oil cleaning and regular analyses help you avoid system breakdowns."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    )
}

export default PressureAccumulators