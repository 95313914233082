import React from 'react';
import './ImageDescription.css'; // Import your custom CSS file for styling
import { useTranslation } from 'react-i18next';

const ImageDescription = ({ title, subtitle, paragraphs, imageSrc, alignment }) => {
    const { t } = useTranslation();
    const containerStyle = {
        textAlign: alignment || 'center'
    };

    return (
        <div className="custom-component-container" style={containerStyle}>
            {title && <h1 className="custom-title">{t(title)}</h1>}
            {imageSrc && <img src={imageSrc} alt="Custom Image" className="custom-image" />}
            <p className="custom-subtitle">{t(subtitle)}</p>
            {paragraphs.map((paragraph, index) => (
                <p key={index} className="custom-paragraph">{t(paragraph)}</p>
            ))}
        </div>
    );
};

export default ImageDescription;
