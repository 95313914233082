import React from 'react';

function NewsEventsPage() {
  return (
    <div>
      <h1>News and Events</h1>
      <p>Stay up-to-date with the latest news and events from HANSA-FLEX.</p>
    </div>
  );
}

export default NewsEventsPage;