import React from 'react';
import { Carousel } from 'react-bootstrap';
import FullimageCard from '../FullImageCard/FullimageCard';

const FullImageCarousel = ({ carouselItems }) => {
    return (
        <Carousel>
            {carouselItems.map((item, index) => (
                <Carousel.Item key={index} interval={3000}>
                    <FullimageCard
                        imageSrc={item.imageSrc}
                        title={item.title}
                        subtitle={item.subtitle}
                        paragraph={item.paragraph}
                        listItems={item.listItems}
                    />
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default FullImageCarousel;
