import React, { useEffect } from 'react';
import ImageDescription from '../../Components/ImageDescription/ImageDescription';
import imgIree from '../../assets/news-events/iree.jpg';


function Iree() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);
    return (
        <div>
            <ImageDescription imageSrc={imgIree} title={"HANSA-FLEX at IREE"} alignment={'center'}
                paragraphs={["HANSA-FLEX India is thrilled to have participated in the 15th edition of the International Railway Equipment Exhibition (IREE), which took place from October 12 to 14, 2023, at Pragati Maidan, New Delhi. IREE stands as Asia's largest platform dedicated to the Rail Transportation Sector, and this event was organized by the Confederation of Indian Industry (CII) in collaboration with the Ministry of Railways, Government of India."
                    , "During our presence at IREE, we had the opportunity to showcase our extensive range of products and services, emphasizing our unwavering commitment to excellence in the rail transportation sector. With the active involvement and support of Indian Railways, one of the world's largest rail networks, HANSA-FLEX India was proud to demonstrate our capabilities in serving this vital industry. Indian Railways, operating a vast network of over 12,000 trains and connecting thousands of stations, plays a crucial role in daily passenger and freight transportation across India."
                    , "Our participation at IREE allowed us to engage with industry professionals, showcase our innovative solutions, and highlight the value we bring to the rail transportation sector. We were delighted to welcome visitors to our booth, share insights, and discuss how HANSA-FLEX India contributes to the efficiency and reliability of India's impressive rail network."
                ]}
            />
        </div>
    );
}

export default Iree;