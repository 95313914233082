import React from 'react';

function HansaFlexIndiaPage() {
  return (
    <div>
      <h1>HANSA-FLEX India</h1>
      <p>Welcome to HANSA-FLEX India. Learn more about our operations in India.</p>
    </div>
  );
}

export default HansaFlexIndiaPage;