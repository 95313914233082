import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgKanban_Centerspread from '../../../assets/Services/Logistics solutions/Kanban delivery/Kanban_Centerspread.jpg'
import imgKanban_Imagetext_01 from '../../../assets/Services/Logistics solutions/Kanban delivery/Kanban_Imagetext_01.jpg'
import imgKanban_Imagetext_02 from '../../../assets/Services/Logistics solutions/Kanban delivery/Kanban_Imagetext_02.jpg'
import imgKanban_Imagetext_03 from '../../../assets/Services/Logistics solutions/Kanban delivery/Kanban_Imagetext_03.jpg'
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook
import ImageText from '../../../Components/ImageText/ImageText'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import { Container, Row, Col } from 'react-bootstrap';
import imgKanban_Imagecard_01 from '../../../assets/Services/Logistics solutions/Kanban delivery/Kanban_Imagecard_01.jpg'
import imgKanban_Imagecard_02 from '../../../assets/Services/Logistics solutions/Kanban delivery/Kanban_Imagecard_02.jpg'
import imgKanban_Imagecard_03 from '../../../assets/Services/Logistics solutions/Kanban delivery/Kanban_Imagecard_03.jpg'


const Kanbandelivery = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;

  const Kanban_Imagetext_01 = {
    image: imgKanban_Imagetext_01,
    title: 'Warehousing and delivery directly to the production line',
    description: ['The kanban system works on the pull principle. This involves using two identical filled storage containers. Goods are taken from only one of the containers. Only when this container is empty is a replenishment order issued and the goods taken from the second storage container. The replenishment order arrives in good time and before the goods in the second container have all been used. The goods do not have to be stored in one central stockpile, which would need more storage area.'],
  }

  const Kanban_Imagetext_02 = {
    image: imgKanban_Imagetext_02,
    title: 'Mobile demand determination by scanner',
    description: ['The demand for goods is reported to the nearest HANSA-FLEX branch by scanning the empty storage containers in real-time via the customer’s warehouse management system. The HANSA-FLEX branch employees then pick the desired goods in exactly the quantity required. As soon as the customer’s empty container has been refilled, it is scanned again and registered as filled. The goods are always filled in accordance with the first-in first-out principle so that no new article is stored for too long. This is particularly relevant for goods with a limited shelf-life. Hydraulic hose lines, for example, can be stored only for a maximum of two years. After then they can no longer be used.'],
  }


  const Kanban_Imagetext_03 = {
    image: imgKanban_Imagetext_03,
    title: 'Solution-focused advice and cooperation',
    description: ['The logistics experts at HANSA-FLEX give you comprehensive advice on whether and how a kanban system could be implemented in your production facilities. The experienced professionals make an assessment on site using their trained eye for hidden potential and extensive know-how to find the most efficient solution for you. On request, HANSA-FLEX can take over the complete warehousing and logistics for all the goods in the kanban system, including sorting and delivery directly to the production line.'],
  }


  const featuresData = [
    {
      title: "More time for your core business",
      description: "HANSA-FLEX ensures that the right goods are available at the right time in the right place.",
      image: imgMark
    },
    {
      title: "Minimum tied-up capital",
      description: "Components are delivered in response to need and there are no surpluses kept in store.",
      image: imgMark
    },
    {
      title: "Products supplied as kits",
      description: "Pre-kitted component groups can be managed as individual parts in the kanban inventory.",
      image: imgMark
    },
    {
      title: "Small ecological footprint",
      description: "Reusable containers, no use of cardboard boxes, reduced transport requirement and weight.",
      image: imgMark
    }
    // Add more objects for additional FeaturesCard data
  ];





  return (
    <div>
      <CenterStage
        imageSrc={imgKanban_Centerspread}
        title={"PARTS AND COMPONENTS ARRIVE PRECISELY WHEN NEEDED USING KANBAN DELIVERY"}
        paragraph={"HANSA-FLEX offers kanban delivery as part of an optimally configured, customised logistics system. Delivery based on the kanban principle is very suitable for companies with a consistently organised production system and a demand for a wide range of parts. Short transport routes and Just-In-Time delivery to the production line exactly when needed can considerably reduce procurement costs."}
      />

      <div >
        <br />
        <ImageText data={Kanban_Imagetext_01} left={false} />
      </div>

      <div >
        <br />
        <ImageText data={Kanban_Imagetext_02} />
      </div>

      <div >
        <br />
        <ImageText data={Kanban_Imagetext_03} left={false} />
      </div>

      <SectionHeader
        title={"YOUR ADVANTAGES WITH THE KANBAN SYSTEM"}
        subtitle={"With the HANSA-FLEX kanban system, you can count on material being available when you need it, save storage space and reduce procurement costs."}
      />

      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>

      <SectionHeader
        title={"MORE WAREHOUSING AND LOGISTICS SERVICES FROM HANSA-FLEX"}
        subtitle={""}
      />

      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgKanban_Imagetext_02} learnMoreLink="/hydraulic-hoses" title="Efficient manufacturing processes with parts supplied in kits" desc="Ready-to-install assembly kits of parts minimise initial costs, offer maximum quality and contribute to sustained increases in productivity." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgKanban_Imagecard_02} learnMoreLink="/metal-hoses" title="Works-in-works manufacturing directly on site" desc="Ready-to-install assembly kits of parts minimise initial costs, offer maximum quality and contribute to sustained increases in productivity." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgKanban_Imagetext_03} learnMoreLink="/Industrial-hoses" title="Customer-specific warehouse management" desc="Would you like to optimise your warehouse management? HANSA-FLEX is happy to provide advice and support to achieve this aim, working with you to find the solution that best suits your needs." />
          </Col>

        </Row>
      </Container>

      <div >
        <br />
        <ImageText data={imgKanban_Imagetext_01} left={false} />
      </div>

      <div >
        <br />
        <ImageText data={imgKanban_Imagetext_02} />
      </div>












    </div>
  )
}

export default Kanbandelivery