import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import imgMark from '../../../assets/mark.png';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import HFTabs from '../../../Components/HfTabs/HfTabs';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';

import imgStage from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/Stage.jpg'
import imgAnalogue from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/Analogue.jpg'
import imgPrecisionTemp from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/PrecisionTemp.jpg'
import imgCompactLevel from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/CompactLevel.jpg'
import imgAdnice from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/Adnice.jpg'
import imgServicing from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/Servicing.jpg'
import imgOptimization from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/Optimization.jpg'
import imgRebundency from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/Rebundency.jpg'
import imgChoosingSensor from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/ChoosingSensor.jpg'
import imgPreventWear from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/PreventWear.jpg'
import imgMeasuringTech from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/MeasuringTech.jpg'
import imgStock from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/Stock.jpg'
import imgUnits from '../../../assets/Products/DriveAndControlTechnology/SwitchesAndSensors/Units.jpg'


const SwitchesAndSensors = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const switchAndSecsor1 = {
        image: imgAnalogue,
        title: 'Analogue and digital pressure switches and sensors',
        description: 'Our online shop has a huge choice of mechanical pressure switches and electronic pressure sensors for automated monitoring and control of pressure levels in hydraulic systems.',
        listItems: ["Mechanical pressure switches for pressure ranges of 5 to 630 bar", "Electronic pressure sensors optionally supplied with digital displays", "Compact unit comprising pressure switch and pressure sensor with LED display"],
        buttonName: "ALL PRODUCTS"
    }

    const switchAndSecsor2 = {
        image: imgPrecisionTemp,
        title: 'Precision temperature switches with various switching points',
        description: 'Having the correct oil temperature is crucial to the proper functioning of a hydraulic system. Temperature switches allow you to reliably monitor the temperature of your hydraulic oil.',
        listItems: ["For installation in pipelines or hydraulic tanks", "Various dip pipe lengths", "Switch hysteresis 10 Kelvin (+/- 5 Kelvin)"],
        buttonName: "VIEW NOW"
    }

    const switchAndSecsor3 = {
        image: imgCompactLevel,
        title: 'Compact level and temperature switches',
        description: 'Combined level and temperature switches from HANSA-FLEX provide a very simple way to control the filling level and temperature of hydraulic fluids.',
        listItems: ["Plastic or aluminium housing", "Programmable variants with LED displays optionally available", "Switch hysteresis: +/- 1 % of full scale value"],
        buttonName: "TO THE ONLINE SHOP"
    }

    const switchAndSecsor4 = {
        image: imgRebundency,
        title: 'Redundancy in the equipment of systems',
        description: 'Faults or failures can occur in any electronic system. For this reason, it is sensible in certain cases to fit more than one switch in a hydraulic system. With redundancy built into the design of the control electronics, your system is reliably protected against switching faults should a switch fail. In addition, the fact that two measurements are taken gives a second value for comparison purposes. This also allows a failing switch to be detected. Safety can be further increased by adding an additional, purely visual display.',
    }

    const switchAndSecsor5 = {
        image: imgChoosingSensor,
        title: 'Choosing the right sensor',
        description: 'The choice of sensors depends on the installation situation, medium properties and ambient conditions. The application also determines the requirements in accordance with the relevant standards with respect to temperature resistance, IP type and shock and vibration load strength. You should consider the following when selecting your sensor:',
        listItems: ["Measurement accuracy, measurement range, measured quantity and output signal strength", "Electrical and hydraulic connections", "Integration into machine and system controls"],
    }

    const switchAndSecsor6 = {
        image: imgPreventWear,
        title: 'Prevent wear',
        description: 'Like all other system components, sensors are not 100 % protected from wear. However, you can considerably extend the service life of sensors by following a few simple rules:',
        listItems: ["Do not exceed the specified measuring ranges.", "Do not use sensors in media for which they were not designed.", "Ensure the materials they are made from are suitable for the conditions of use.", "Operate the sensors with the correct electrical connections and parameters.", "Avoid overtemperature, shock and vibrations."],
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Comprehensive product portfolio",
            description: "Analogue and digital switches for monitoring pressure, temperature and filling level.",
            image: imgMark
        },
        {
            title: "High availability",
            description: "Well-stocked warehouses ensure short delivery times for your required components.",
            image: imgMark
        },
        {
            title: "Customised configuration",
            description: "Switches and sensors can be supplied with various connections to suit your specific requirements.",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Comprehensive advice",
            description: "The fluid experts from HANSA-FLEX are always happy to answer questions about switch and sensor technology and provide comprehensive technical advice on the following subjects:",
            listItems: ["Choice and optimisation of design", "Installation in hydraulic systems and controls", "Efficient operation and regular servicing"],
            image: imgAdnice
        },
        {
            title: "Servicing of hydraulic components and machines",
            description: "With our comprehensive know-how and extensive product portfolio in all areas of hydraulics, we can undertake all the servicing of your machines and systems in accordance with your requirements. This can relieve your employees’ workload and reduce preventative maintenance standstills.",
            listItems: ["Completion of one-off and recurring servicing tasks", "Performed by experienced and trained service technicians", "Quick and straightforward replacement of components"],
            image: imgServicing
        },
        {
            title: "Optimisation of machines and systems",
            description: "As a system partner, we provide extensive support to you with all aspects of optimisation and modernisation of your machines and systems, with the aim of increasing efficiency and operational reliability.",
            listItems: ["Continuous condition monitoring", "Effective fault analysis and competent fault rectification", "Identification of further potential for improvement"],
            image: imgOptimization
        },
    ];

    const tabTitles = [
        { title: "Advice", eventKey: "Advice" },
        { title: "Servicing", eventKey: "Servicing" },
        { title: "Optimisation", eventKey: "Optimisation" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"SWITCHES AND SENSORS FOR HYDRAULICS"}
                    paragraph={"Switches and sensors for various process parameters, such as pressure, filling level and temperature, are used to regulate, control and monitor hydraulic systems. HANSA-FLEX is a one-stop partner supplying a large selection of durable pressure, temperature and level switches and sensors. In addition, our experts advise you on the selection of the most suitable solution and support you in the optimisation of your machines and systems."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"SWITCHES AND SENSORS FOR PRESSURE, TEMPERATURE AND LEVEL CONTROL"}
                    subtitle={"The HANSA-FLEX portfolio includes robust, analogue hydraulic switches and state-of-the-art sensors for interlinking hydraulic systems with electronic controls for all industries and applications."}
                />
                <ImageText
                    data={switchAndSecsor1} />
                <ImageText
                    data={switchAndSecsor2}
                    left={false} />
                <ImageText
                    data={switchAndSecsor3} />
            </div>

            <div>
                <SectionHeader
                    title={"ANALOGUE AND DIGITAL SWITCHES AND SENSORS FROM HANSA-FLEX"}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"OUR MISSION IS TO SATISFY YOUR REQUIREMENTS"}
                    subtitle={"Have you not found the right solution for your application in our online shop? In addition to the products listed there, we also have access to numerous other switches, sensors and stroke measuring systems for cylinders available elsewhere on the market. Give us a call! We will find the best solution for you."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"ADVICE AND SUPPORT FROM EXPERTS"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"GOOD TO KNOW"}
                    subtitle={"What you should consider when selecting, installing and operating sensors and switches in hydraulic systems."}
                />
                <ImageText
                    data={switchAndSecsor4} />
                <ImageText
                    data={switchAndSecsor5}
                    left={false} />
                <ImageText
                    data={switchAndSecsor6} />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgMeasuringTech}
                                title="Measuring technology and display instruments for hydraulic systems"
                                desc={"HANSA-FLEX has measuring instruments from well-known manufacturers to monitor and display pressure, flow, temperature and particle density."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgStock}
                                title="Over 4,500 different hydraulic components always in stock"
                                desc={"HANSA-FLEX offers an extensive portfolio of high-quality hydraulic components such as pumps, motors, cylinders, filters, valves, switches and sensors."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgUnits}
                                title="Hydraulic units built to the customer’s specific requirements"
                                desc={"HANSA-FLEX uses its extensive know-how to support customers in the design, project planning and manufacture of one-off and small batch hydraulic units and with bringing..."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    )
}


export default SwitchesAndSensors