import React from 'react';
import Card from 'react-bootstrap/Card';
import './ImageCard.css';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ImageCard = ({ image, title, desc, learnMoreLink }) => {
  const { t } = useTranslation();
  function cutStringToWordCount(str, count) {
    const words = str.split(' ');
    if (words.length <= count) {
      return str;
    }
    return words.slice(0, count).join(' ') + '...';
  }

  return (
    <Card className="image-card">
      <Card.Img className='square-corner ht-ct' variant="top" src={image} />
      <div className="image-overlay-c">
        <div className="content">
          <h2 className="card-title">{t(title)}</h2>
          <p className="card-description">{cutStringToWordCount(t(desc), 32)}
            <br /><br />
            {learnMoreLink && (
              // Render either Link or anchor tag based on link type
              learnMoreLink.startsWith('/') ? (
                <Link to={learnMoreLink} className="learn-more-link">{t('Learn More')} <BsArrowRight className="arrow-icon" /></Link>
              ) : (
                <a href={learnMoreLink} className="learn-more-link">{t('Learn More')} <BsArrowRight className="arrow-icon" /></a>
              )
            )}
          </p>
        </div>
      </div>
    </Card>
  );
}

export default ImageCard;
