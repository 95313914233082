import React, { useEffect } from 'react'
import CenterStage from '../../../Components/CenterStage/CenterStage'
import imgPrototype_Centerspread from '../../../assets/Services/Engineering/Prototype manufacture/Prototype_Centerspread.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../Components/ImageText/ImageText'
import imgPrototype_Imagetext_01 from '../../../assets/Services/Engineering/Prototype manufacture/Prototype_Imagetext_01.jpg'
import imgPrototype_Imagetext_02 from '../../../assets/Services/Engineering/Prototype manufacture/Prototype_Imagetext_02.jpg'
import imgPrototype_Imagetext_03 from '../../../assets/Services/Engineering/Prototype manufacture/Prototype_Imagetext_03.jpg'
import imgPrototype_Imagetext_04 from '../../../assets/Services/Engineering/Prototype manufacture/Prototype_Imagetext_04.jpg'
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../assets/mark.png'
import ImageCard from '../../../Components/ImageCard/ImageCard'
import imgPrototype_Imagecard_01 from '../../../assets/Services/Engineering/Prototype manufacture/Prototype_Imagecard_01.jpg'
import imgPrototype_Imagecard_02 from '../../../assets/Services/Engineering/Prototype manufacture/Prototype_Imagecard_02.jpg'
import imgPrototype_Imagecard_03 from '../../../assets/Services/Engineering/Prototype manufacture/Prototype_Imagecard_03.jpg'
import { Container, Row, Col } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook



const Prototypemanufacture = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);

  const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
  const isMobile = window.innerWidth <= 768;

  const Prototype_Imagetext_01 = {
    image: imgPrototype_Imagetext_01,
    title: 'Customised prototypes in line with customer specifications',
    description: ['If you require a hydraulic component or component group that is not available anywhere on the market, you do not need special technical knowledge or a drawing with HANSA-FLEX. We take your individual requirements for the component or component group, design it and prepare the final drawing. We are able to implement your specific requirements with respect to functionality, dimensioning and weight precisely. Of course, we further develop the drawing jointly with you until we have the optimum solution for your project.'],
  }

  const Prototype_Imagetext_02 = {
    image: imgPrototype_Imagetext_02,
    title: 'Manufacture and quality assurance and compliance with standards',
    description: ['For the manufacture of prototypes, we draw on our comprehensive know-how, state-of-the-art production facilities, numerous certifications and our practice-proven quality management system.'],
  }


  const Prototype_Imagetext_03 = {
    image: imgPrototype_Imagetext_03,
    title: 'Bringing into first use, advice and assistance up to series production',
    description: ['No prototype leaves our production facilities without undergoing a thorough individual approval process. If the prototype is to go into series production, we are there to advise and assist you all the way to series production readiness and can undertake its manufacture in-house at HANSA-FLEX or at the premises of one of our expert partners.'],

  }


  const Prototype_Imagetext_04 = {
    image: imgPrototype_Imagetext_04,
    title: 'Highest design standards for series production',
    description: ['The 3D drawings produced by our technicians specify the high HANSA-FLEX quality standard to which the suppliers are to manufacture all the components. Thus we achieve high process reliability and can offer you an optimum price-performance ratio. Regardless of where a component is manufactured, it always has an identical appearance. Standardised production means the likelihood of tolerance errors when manufacturing hydraulic machines and systems is reduced dramatically.'],
  }


  const   featuresData = [
    {
      title: "Specific specialist knowledge in all areas",
      description: "HANSA-FLEX has extensive experience in all disciplines of hydraulics.",
      image: imgMark
    },
    {
      title: "Individually made to the customer’s wishes",
      description: "We are completely familiar with the process of prototype manufacture and implement your wishes.",
      image: imgMark
    },
    {
      title: "Compliant with standards and certified",
      description: "We manufacture using certified employees and in accordance with all relevant standards",
      image: imgMark
    },
    {
      title: "Advice and assistance right up to series production",
      description: "We manufacture products as one off items or in small or large batch series production.",
      image: imgMark
    }
    // Add more objects for additional FeaturesCard data
  ];

  return (
    <div>
      <CenterStage
        imageSrc={imgPrototype_Centerspread}
        title={"PROTOTYPE MANUFACTURE AND PRODUCTION AS ONE-OFF ITEMS OR IN SMALL OR LARGE BATCHES"}
        paragraph={"If you require specific hydraulic components or component groups that are not available off the shelf, the HANSA-FLEX prototype manufacturing teams are always pleased to help. We manufacture individual items to suit customer specifications, drawings or a sample, bring the prototype into first use, and, if required, advise and assist you with the product to reach series production readiness. With specialist knowledge, certifications and state-of-the-art machines, we create efficient solutions to meet every challenge."}
      />



      <div >
        <SectionHeader
          title={"PROTOTYPE MANUFACTURE, THE COMPLETE PROCESS"}
          subtitle={"Prototype manufacture with HANSA-FLEX includes design, manufacture, quality control, bringing into first use and advice and assistance up to series production readiness."}
        />
      </div>

      <div >
        <br />
        <ImageText data={Prototype_Imagetext_01} left={false} />
      </div>

      <div >
        <br />
        <ImageText data={Prototype_Imagetext_02} />
      </div>

      <div >
        <br />
        <ImageText data={Prototype_Imagetext_03} left={false} />
      </div>

      <div >
        <SectionHeader
          title={"PROTOTYPE MANUFACTURE USING SKILLS FROM DIFFERENT DISCIPLINES"}
          subtitle={"For prototype manufacture, HANSA-FLEX calls on its highly specialised hydraulic engineering teams."}
        />
      </div>


      <div >
        <br />
        <ImageText data={Prototype_Imagetext_04} />
      </div>


      <div >
        <SectionHeader
          title={"YOUR ADVANTAGES WITH HANSA-FLEX PROTOTYPE MANUFACTURE"}
          subtitle={"Gain from our comprehensive hydraulics know-how in the design, planning and implementation of your project."}
        />
      </div>

      <div>
        <FeatureCardCarousel featuresData={featuresData} />
      </div>






      <div >
        <SectionHeader
          title={"PROTOTYPE MANUFACTURE TO SUIT THE CUSTOMER’S NEED"}
          subtitle={"Prototype manufacture at HANSA-FLEX begins with consultation without condescension between equal partners, which results in a product that fulfils the highest functional and quality standards in the desired application."}
        />
      </div>

      <div >
        <SectionHeader
          title={"YOU MAY ALSO BE INTERESTED IN THIS"}
          subtitle={""}
        />
      </div>

      <Container className="custom-container">
        <Row>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgPrototype_Imagecard_01} learnMoreLink="/hydraulic-hoses" title="HANSA-FLEX hydraulic hoses" desc="We offer a large selection of hydraulic hoses in elastomeric and thermoplastic materials for almost every field of use in mobile and industrial hydraulics." />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-left"}>
            <ImageCard image={imgPrototype_Imagecard_02} learnMoreLink="/metal-hoses" title="Precision bent pipes" desc="In the HANSA-FLEX pipe bending centre, pipelines are manufactured individually made-to-measure or in series production. Our range of products in this field is" />
          </Col>
          <Col sm={12} md={6} lg={4} data-aos={isMobile ? "fade-up" : "fade-right"}>
            <ImageCard image={imgPrototype_Imagecard_03} learnMoreLink="/hydraulic-hoses" title="Sealing technology always available" desc="With large warehouse stocks, a wide range of materials and manufacturing methods, competent advice and rapid order processing, HANSA‑FLEX leaves no wish unfulfilled." />
          </Col>


        </Row>
      </Container>
















    </div>
  )
}

export default Prototypemanufacture