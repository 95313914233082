import React, { useEffect } from 'react';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import imgMetalHoseForExtreme from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/metalHosesForExtreme.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import imgHighQualityMaterials from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/highQualityMaterials.jpg'
import imgTheMostSuitable from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/theMostSuitable.jpg'
import imgExtensiveAdvice from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/extensiveadvice.jpg'
import ImageText from '../../../Components/ImageText/ImageText';
import imgMark from '../../../assets/mark.png';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgPTFEHoses from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/ptfeHoses.jpg'
import imgExpansion from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/expansionJoints.jpg'
import imgOneOff from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/oneOffSpecial.jpg'
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import imgTopClassMetalHose from '../../../assets/Products/HosesAndHoseLines/Metal Hoses/topClassMetalHose.jpg'




function MetalHoses() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const HighQuality1 = {
        image: imgHighQualityMaterials,
        title: 'High-quality materials and professional workmanship',
        description: 'First-class corrugated hoses in stainless steel form the basis for the quality of metal hoses from HANSA-FLEX. However, it is only the certified assembly processes at the hands of trained personnel that turn them into truly robust hose lines. Using the latest methods, we do not limit testing to one-off items only; we also test up to 100 % of series-produced components for leaks.',
        listItems: ["Highest standards of quality and precision", "Certified assembly processes", "100 % leakage testing"]
    }

    const HighQuality2 = {
        image: imgTheMostSuitable,
        title: 'The most suitable fitting for every situation',
        description: 'Metal hose lines are used widely, not least because of their wide temperature range, high strength under vacuum and availability in many different sizes. As well as acids and alkalis, they can also transport hot liquids or gases.',
        listItems: ["Wide temperature range of -196 °C to +550 °C", "Suitable for pressures up to 300 bar and vacuum", "Nominal diameters from DN 6 to DN 250 mm"]
    }

    const HighQuality3 = {
        image: imgExtensiveAdvice,
        title: 'Extensive advice from HANSA-FLEX',
        description: 'HANSA-FLEX offers a comprehensive range of products with a large choice of different hose types. The experienced advisers at HANSA-FLEX draw on their fluid technology expertise to find the optimum product for you. They also provide flexible and responsive support to you throughout the system design phase.',
        listItems: ["Wide range of services for hose inspection, testing and failure analyses", "Reliable implementation of customer-specific oil cleanliness requirements", "Excellent material availability and individualised customer solutions"]
    }

    const HighQuality4 = {
        image: imgTopClassMetalHose,
        listItems: ["Parallel corrugation construction for high performance", "Different constructions (single and double-walled)", "Longitudinally welded", "Various hose materials, e.g. stainless steel 1.4404", "Braid reinforcement, e.g. stainless steel 1.4301", "Components designed to work together perfectly"]
    }

    const hfMetalHoses = [
        {
            title: "Numerous variants",
            description: "Metal hoses are available as double-skinned or as stripwound hose lines",
            image: imgMark
        },
        {
            title: "Extensive quality testing",
            description: "Every hose line undergoes stringent testing with pressure test certificates available on request",
            image: imgMark
        },
        {
            title: "Highest cleanliness",
            description: "We supply metal hose lines to suit the customer’s requirements for being free of oil, grease and LABS",
            image: imgMark
        },
        {
            title: "Rapid delivery",
            description: "We deliver all HANSA-FLEX hose lines to you reliably on schedule.",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgMetalHoseForExtreme}
                    title={"METAL HOSES FOR EXTREME CONDITIONS"}
                    paragraph={"The materials used for metal hoses make them extremely resistant to corrosion and abrasion. Their excellent temperature tolerance, strength under vacuum and outstandingly low permeability make metal hoses the preferred choice for transporting solid, liquid and gaseous substances under particularly demanding conditions. HANSA-FLEX offers comprehensive advice on the selection of the best metal hose solution."}
                /> 

            </div>

            <div>
                <SectionHeader
                    title={"THE HIGHEST QUALITY AND RELIABILITY WITH HANSA-FLEX"}
                />
            </div>

            <div>
                <ImageText
                    data={HighQuality1} />

                <ImageText
                    data={HighQuality2}
                    left={false} />

                <ImageText
                    data={HighQuality3} />
            </div>

            <div>
                <div>
                    <SectionHeader
                        title={"HANSA-FLEX METAL HOSES WITH OUTSTANDING PERFORMANCE FEATURES"}
                        subtitle={"HANSA-FLEX metal hose lines manufactured from high-grade materials using state-of-the-art processing methods. This is how we ensure our metal hoses fulfil the requirements of all the relevant standards and approvals, such as DIN EN ISO 15085-2 CL 1, 2014/68/EU (PED) and DNV."}
                    />
                    <FeatureCardCarousel
                        featuresData={hfMetalHoses} />
                </div>
            </div>

            <div>
                <SectionHeader
                    title={"TOP-CLASS METAL HOSES FROM THE FLUID SPECIALISTS"}
                    subtitle={"High temperature tolerance and resistance to aggressive media lie behind the excellent performance characteristics of HANSA-FLEX metal hoses."}
                />
                <ImageText data={HighQuality4} />
            </div>

            {/* TODO: Carousal */}
            {/* TODO: Video */}

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgPTFEHoses} learnMoreLink={"/Ptfe-hoses"}
                                title="PTFE hoses with high chemical resistance"
                                desc={"Because of their high chemical resistance against most media, PTFE hoses from HANSA-FLEX are the first choice for all challenging applications."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgExpansion} learnMoreLink={"/Expansionjoints"}
                                title="Expansion joints for hoses and pipes"
                                desc={"High temperature tolerance and resistance to aggressive media lie behind the special performance capabilities of metal hoses."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgOneOff} learnMoreLink={"/Customisedassembly"}
                                title="One-off special solutions"
                                desc={"Solutions have to be at least as diverse and wide-ranging as the applications. HANSA-FLEX solutions can be anything from one-off, single products to series-produced batches..."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

        </div>
    );
}
export default MetalHoses;
