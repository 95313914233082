import React, { useEffect } from 'react'
import CenterStage from '../../../../Components/CenterStage/CenterStage';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../../Components/ImageCard/ImageCard';
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader'
import HFTab from '../../../../Components/HfTabs/HfTabs'
import FeatureCardCarousel from '../../../../Components/FeaturesCardCarousal/FeatureCardCarousal'
import imgMark from '../../../../assets/mark.png'
import ImageText from '../../../../Components/ImageText/ImageText'

import Stage from '../../../../assets/Services/PreventativeMaintainance/Inspections/Stage.jpg'
import HydraulicHose from '../../../../assets/Services/PreventativeMaintainance/Inspections/HydraulicHose.jpg'
import IndustrialHose from '../../../../assets/Services/PreventativeMaintainance/Inspections/IndustrialHose.jpg'
import PressureAccumulator from '../../../../assets/Services/PreventativeMaintainance/Inspections/PressureAccumulator.jpg'
import LiftingPlatForm from '../../../../assets/Services/PreventativeMaintainance/Inspections/LiftingPlatForm.jpg'
import SaferOperation from '../../../../assets/Services/PreventativeMaintainance/Inspections/SaferOperation.jpg'
import Scope from '../../../../assets/Services/PreventativeMaintainance/Inspections/Scope.jpg'
import Creation from '../../../../assets/Services/PreventativeMaintainance/Inspections/Creation.jpg'
import HoseManagement from '../../../../assets/Services/PreventativeMaintainance/Inspections/HoseManagement.jpg'
import ComprehensiveServices from '../../../../assets/Services/PreventativeMaintainance/Inspections/ComprehensiveServices.jpg'
import OnSafeSide from '../../../../assets/Services/PreventativeMaintainance/Inspections/OnSafeSide.jpg'
import AuthorisedPersons from '../../../../assets/Services/PreventativeMaintainance/Inspections/AuthorisedPersons.jpg'


const Inspection = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    //HfTab data 2
    const imageTextData2 = [
        {
            title: "Scope of documentation",
            description: "As part of the inspection, our experts document all the results in a clear and legally compliant manner for you. All the customer’s data and information about the system or machine are collected on our standard record sheets so that there is no doubt later about the details of the inspection. The documentation gives you information about:",
            listItems: [
                "All the performed inspection tasks",
                "The date of the next inspection",
                "Any defects or abnormalities"
            ],
            image: Scope
        },
        {
            title: "Creation of the documentation",
            description: "The documentation includes photographs of the system or machine to be inspected, such as photographs of the identification plate or particular construction features as verifiable evidence of its general condition. Any defects are photographed and filed. The photographs are a constituent part of the documentation and are sent to you together with the inspection sheets in digital form after completion.",
            image: Creation
        },
        {
            title: "Digital data capture and management with X-CODE Manager",
            description: 'The documentation of the inspection of hydraulic hose lines is done in our hose management software. All the important information about every single hose line is stored there:',
            listItems: [
                "Unique identification by X-CODE",
                "Manufacture, installation and, if necessary, replacement",
                "Risk assessment and inspection interval"
            ],
            image: HoseManagement
        },
    ];

    const tabTitles2 = [
        { title: "Scope", eventKey: "Scope" },
        { title: "Creation", eventKey: "Creation" },
        { title: "Hose management", eventKey: "Hose management" },
    ];

    const featuresData = [
        {
            title: "Ready for use again",
            description: "The fluid experts overhaul defective hydraulic components such as cylinders and pressure accumulators",
            image: imgMark
        },
        {
            title: "In good condition",
            description: "HANSA-FLEX Industrial Service supports you in your servicing and maintenance work",
            image: imgMark
        },
        {
            title: "Oil care made easy",
            description: "Avoid oil-related damage with the benefit of know-how from HANSA-FLEX fluid experts",
            image: imgMark
        },
        {
            title: "Smart hose management",
            description: "With X-CODE Manager you have a complete picture of your hose lines, machines and inspection dates",
            image: imgMark
        }
    ];

    const data1 = {
        image: ComprehensiveServices,
        title: 'Comprehensive services all from the same provider',
        description: 'We are your service partner for everything to do with the inspection of hydraulic work equipment. We support you in the following tasks:',
        listItems: [
            "Performance of inspections including documentation",
            "Advice and assistance in the preparation of risk assessments",
            "Installation and removal of the components to be inspected",
            "Replacement and overhaul of defective components",
            "Advice on the optimisation of machines and systems"
        ]

    }

    const data2 = {
        image: OnSafeSide,
        title: 'Always on the safe side',
        description: "With services from HANSA-FLEX you are better assured of legal compliance. When performing inspections, we make sure we comply with the legal requirements and document the results in an inspection record:",
        listItems: [
            "German Industrial Safety Act (BetrSichV)",
            "Machinery Directive / EU Use of Work Equipment Directive 2009/104/EC",
            "European Pressure Equipment Directive (PED)",
            "Germany’s statutory accident insurance organisation (DGUV)",
            "Technical rules for Industrial Health and Safety (TRBS)"
        ]
    }

    const data3 = {
        image: AuthorisedPersons,
        description: 'Our employees are qualified as qualified individuals in the following areas:',
        listItems: [
            "Qualified people for line technology",
            "Qualified persons for pressure storage",
            "Qualified people for wind turbines",
            "Qualified people for lifting platforms and loading ramps",
            "Qualified people for industrial hoses"
        ]
    }


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"HANSA-FLEX IS YOUR RELIABLE PARTNER FOR INSPECTIONS"}
                    paragraph={"Hydraulic systems work extremely hard. Regular inspections are therefore obligatory in order to ensure reliable and safe operation of systems and machines. The fluid specialists qualified as officially authorised persons at HANSA-FLEX support you with the inspection of hydraulic hose lines, industrial hoses, pressure accumulators, lifting platforms and loading bridges in accordance with the legally prescribed requirements such as set out in DGUV and BetrSichV."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"WORK EQUIPMENT AND OPERATING MATERIALS RELIABLY INSPECTED WITH HANSA-FLEX"}
                    subtitle={"The trained and certified experts at HANSA-FLEX perform the regularly prescribed inspections of hydraulic systems including documentation."}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={8}><ImageCard image={HydraulicHose}
                                title="Inspection of hydraulic hose lines"
                                desc={"Hydraulic hose lines are fundamental components of machines and systems. They require comprehensive care and must be professionally inspected by authorised persons every 12 months after being brought into first use."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={IndustrialHose}
                                title="Inspection of industrial hoses"
                                desc={"We inspect industrial hose lines carrying chemicals, foodstuff, pharmaceuticals and other media including the preparation of the legally required inspection certificate."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={PressureAccumulator}
                                title="Inspection of pressure accumulators"
                                desc={"HANSA-FLEX employees are officially authorised persons for pressure accumulators and perform the legally prescribed visual inspection of pressure."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={LiftingPlatForm}
                                title="Inspection of lifting platforms and loading bridges"
                                desc={"The experts at HANSA-FLEX are officially authorised persons for lifting platforms and loading bridges and perform the annual inspections of this equipment for you."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={SaferOperation}
                                title="Safer operations through preventive maintenance"
                                desc={"The German Industrial Safety Act calls for ongoing regular inspections of work equipment such as machines in addition to the inspection performed before bringing."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>

            <div>
                <SectionHeader
                    title={"LEGALLY COMPLIANT DOCUMENTATION BASED ON INSPECTION RECORDS"}
                />
                <HFTab imageTextData={imageTextData2} tabTitles={tabTitles2} />
            </div>

            <div>
                <SectionHeader
                    title={"COMPREHENSIVE SERVICE FOR YOUR SYSTEMS AND MACHINES"}
                    subtitle={"Our employees are experienced fluid specialists and support you with all tasks to do with the servicing, maintenance and overhaul of your pneumatic and hydraulic systems."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"HYDRAULICS KNOWLEDGE AND ADVANCED TRAINING FROM EXPERTS"}
                    subtitle={"In cooperation with the International Hydraulics Academy (IHA), HANSA-FLEX runs seminars for engineers, technicians, installers and hydraulic systems maintenance personnel. Experts pass on their specialist knowledge of safe operation, proper servicing and maintenance."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"ONE CODE FOR EVERY INSTANCE"}
                    subtitle={"The unique X-CODE combines all the information about a specific hose line, forms the basis for ordering a replacement and hose management."}
                />
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"AUTHORISED PERSONS AT HANSA-FLEX"}
                    subtitle={"The experts at HANSA-FLEX who perform the inspection of hydraulic and pneumatic work equipment are certified by independent testing bodies as having the relevant up-to-date specialist knowledge."}
                />
                <ImageText
                    data={data3} />
            </div>

        </div>
    )
}

export default Inspection 