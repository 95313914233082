import React, { useEffect } from 'react';
import imgExeHigPresHose from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/ExtHighPresHose.jpg'
import CenterStage from '../../../Components/CenterStage/CenterStage';
import imgOilAndGas from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/oilAndGas.jpg'
import imgApplication from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/application.jpg'
import imgwaterJet from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/waterJet.jpg'
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import ImageText from '../../../Components/ImageText/ImageText';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';
import imghoseProfessional from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/hoseProfessional.jpg'
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import imgHydraulicUpto700Bar from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/HydraulicUpto700Bar.jpg'
import imgHoseFittingForApplication from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/HoseFittingForApplication.jpg'
import imgCompatibleAccessories from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/CompatibleAccessories.jpg'
import imgPtfeHoses from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/PtfeHoses.jpg'
import imgMetalHoseHighTemp from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/MetalHoseHighTemp.jpg'
import IndustrialHose from '../../../assets/Products/HosesAndHoseLines/Extremely High Pressure Hose/IndustrialHose.jpg'



function ExtremelyHighPerformanceHoses() {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const ExtremelyHigh1 = {
        image: imgOilAndGas,
        title: 'Extremely high-pressure hoses for the oil and gas industry',
        description: 'Thermoplastic high-pressure hoses are very common in the oil and gas industry. As well as a high resistance to chemicals, thermoplastic hoses exhibit low volumetric expansion and are resistant to external pressure. They are typically used to carry gas and nitrogen and for the injection of chemicals and grease.',
    };

    const ExtremelyHigh2 = {
        image: imgApplication,
        title: 'Extremely high-pressure hoses for hydraulic applications',
        description: 'HANSA-FLEX extremely high-pressure hoses are always the first choice in systems where elevated pressures prevail. Indeed, they may have to cope with pressures up to 4,000 bar in the oil and gas industry, water jetting, hydraulic applications and the automotive sector. HANSA-FLEX supplies extremely high-pressure hoses specially customised to suit your requirements and delivered as ready-for-installation hose lines.',
        listItems: ["Light and flexible", "Highly load resistant", "Resistant to ageing, ozone and UV light"]
    };

    const ExtremelyHigh3 = {
        image: imgwaterJet,
        title: 'Extremely high pressure hoses for water jet applications',
        description: 'High-pressure water jetting is used to clean sewers and pipes as well as for the general cleaning of surfaces. Other important fields of application include water jet cutting and concrete surface refurbishment. HANSA-FLEX offers a specially developed range of extremely high-pressure hoses for pressures up to 3,200 bar.',
        listItems: ["Highly load resistant", "The highly abrasion-resistant hose outer cover results in a long service life", "High impulse load strength"]
    };

    const ExtremelyHigh4 = {
        image: imghoseProfessional,
        description: "You can expect the usual high-quality products and services from the well-trained HANSA-FLEX staff, who are also able to advise you on material choice, dimensions, fittings and bringing your systems into use.",
        listItems: ["Comprehensive range of production machinery and equipment", "Customer-specific manufacturing by experienced specialists", "Multi-stage quality control including pressure tests"]
    };

    const certifiedQuality = [
        {
            title: "Available variants",
            description: "Available with internal diameters of 3 mm to 25 mm, these hoses can be used for pressure ranges up to 4,000 bar",
            image: imgMark
        },
        {
            title: "Robust and flexible",
            description: "Two to eight layers of wire braid, reinforced version and with open spiral layer.",
            image: imgMark
        },
        {
            title: "Customised solutions",
            description: "Suitable fittings and accessories in any length",
            image: imgMark
        },

        // Add more objects for additional FeaturesCard data
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgExeHigPresHose}
                    title={"EXTREMELY HIGH-PRESSURE HOSES FOR APPLICATIONS UP TO 3,200 BAR"}
                    paragraph={"HANSA-FLEX extremely high-pressure hoses are always the first choice in systems where elevated pressures prevail. Indeed, they may have to cope with pressures up to 4,000 bar in the oil and gas industry, water jetting, hydraulic applications and the automotive sector. HANSA-FLEX supplies extremely high-pressure hoses specially customised to suit your requirements and delivered as ready-for-installation hose lines."}
                //TODO button
                />
            </div>

            <div>
                <SectionHeader
                    title={"FIELDS OF APPLICATION FOR HANSA-FLEX EXTREMELY HIGH-PRESSURE HOSES"}
                />
                <div>
                    <ImageText
                        data={ExtremelyHigh1} />

                    <ImageText
                        data={ExtremelyHigh2}
                        left={false} />

                    <ImageText
                        data={ExtremelyHigh3} />
                </div>
            </div>

            <div>
                <SectionHeader
                    title={"EXTREMELY HIGH-PRESSURE HOSES IN CERTIFIED QUALITY"}
                    subtitle={"Thermoplastic extremely high-quality hoses with pressure carriers incorporating spirally wound steel wire impress with their quality, service life and reliability."}
                />
                <FeatureCardCarousel
                    featuresData={certifiedQuality} />
            </div>

            <div>
                <SectionHeader
                    title={"EXTREMELY HIGH-PRESSURE HOSE LINES FROM THE HOSE PROFESSIONALS"}
                    subtitle={"We know what matters when working with extremely high pressures. The highest material and manufacturing quality is guaranteed at HANSA-FLEX."}
                />
                <ImageText data={ExtremelyHigh4} />
            </div>

            {/* TODO: TextPoster */}
            {/* TODO: Carousal */}

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4}><ImageCard image={imgHydraulicUpto700Bar} learnMoreLink={"/seven-hundred-hydraulics"}
                                title="Components for high-pressure hydraulics up to 700 bar"
                                desc={"HANSA-FLEX offers a selected range of products for heavy-duty hydraulics with pressures up to 700 bar and can supply customised components for up to 1,000 bar."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgHoseFittingForApplication} learnMoreLink={"https://shop.hansa-flex.cl/en_GB/hose-fittings/c/webcat_HF_SAR"}
                                title="Hose fittings for hydraulic applications"
                                desc={"HANSA-FLEX hose fittings are designed to perfectly match the hydraulic hoses. They also receive a zinc-nickel surface coating as standard."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgCompatibleAccessories} learnMoreLink={"/hose-accessories"}
                                title="Compatible accessories for hose lines"
                                desc={"In addition to first-class hoses, the HANSA-FLEX portfolio of accessories includes rubbing, heat and kinking protection devices and safety equipment such as hose..."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgPtfeHoses} learnMoreLink={"/Ptfe-hoses"}
                                title="PTFE hoses with high chemical resistance"
                                desc={"Because of their high chemical resistance against most media, PTFE hoses from HANSA-FLEX are the first choice for all challenging applications."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={imgMetalHoseHighTemp} learnMoreLink={"/metal-hoses"}
                                title="Metal hoses with high temperature tolerance"
                                desc={"Metal hoses excel in particular with their ability to withstand high mechanical loads, strength under vacuum and high temperature tolerance."} /></Col>

                            <Col sm={12} md={6} lg={4}><ImageCard image={IndustrialHose} learnMoreLink={"/Industrial-hoses"}
                                title="Industrial hoses in the highest quality"
                                desc={"HANSA-FLEX can produce industrial hoses to individual customer requirements quickly and competently in itsown specialist hose workshop."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );

}

export default ExtremelyHighPerformanceHoses;