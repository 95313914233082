import React, { useEffect } from 'react';
import HFTab from '../../../Components/HfTabs/HfTabs';
import CenterStage from '../../../Components/CenterStage/CenterStage';
import SectionHeader from '../../../Components/SectionHeader/SectionHeader';
import { Col, Container, Row } from 'react-bootstrap';
import ImageCard from '../../../Components/ImageCard/ImageCard';
import ImageText from '../../../Components/ImageText/ImageText';
import Promoter from '../../../Components/Promoter/Promoter';
import FeatureCardCarousel from '../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import imgMark from '../../../assets/mark.png';

import Stage from '../../../assets/Services/FluidManagement/OilFilteringAndCleaning/Stage.jpg'
import AvoidOil from '../../../assets/Services/FluidManagement/OilFilteringAndCleaning/AvoidOil.jpg'
import DetectOil from '../../../assets/Services/FluidManagement/OilFilteringAndCleaning/DetectOil.jpg'
import RectifyOil from '../../../assets/Services/FluidManagement/OilFilteringAndCleaning/RectifyOil.jpg'
import PreventOil from '../../../assets/Services/FluidManagement/OilFilteringAndCleaning/PreventOil.jpg'
import Service1 from '../../../assets/Services/FluidManagement/OilFilteringAndCleaning/Service1.jpg'
import Service2 from '../../../assets/Services/FluidManagement/OilFilteringAndCleaning/Service2.jpg'

const OilFilteringAndCleaning = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    //ImageText Data
    const data1 = {
        image: AvoidOil,
        title: 'Avoid oil contamination',
        description: 'Repairs, defective seals or a faulty tank seal can allow dust, and particles of metal or seals to enter the hydraulic system or the oil tank. From there they gradually spread throughout the whole system and damage components such as pumps or valves. The consequences: repairs, failures and long standstills. In addition to regular oil analyses, the selection of the right filter system is extremely important. Whether suction, pressure, return or tank breather filter: there are numerous filtration solutions for a wide range of purposes. HANSA-FLEX fluid technology experts advise you on their selection, design and installation.',
    }

    const data2 = {
        image: DetectOil,
        title: 'Detect oil contamination',
        description: 'Oils carrying high levels of dirt also carry hidden dangers. Many particles are often invisible to the naked eye. The consequences: even the smallest particles circulating undiscovered inside your system can cause abrasion, wear and corrosion. It is just a matter of time until components appear to suddenly fail. To prevent damage progressing this far, the HANSA-FLEX fluid specialists analyse the oil on site or in the laboratory. And what if failure has already occurred? As part of damage detection on faulty components such as pumps, motors and hose lines, we make a list of optimisation measures for the hydraulic system, hydraulic and gear oils, which we would be happy to perform for you.',
    }

    const data3 = {
        image: RectifyOil,
        title: 'Rectify oil contamination',
        description: 'If foreign material was found from an oil analysis, HANSA-FLEX Fluid Service can filter the fluid while the system or machine continues to operate. We provide various high-performance filter systems and flushing equipment for hire or purchase. We also design and manufacture flushing units and bypass filter systems individually to suit your specific requirements. These can be a permanent part of your system. Before an oil change and when bringing into first use, our Fluid Service technicians thoroughly flush your system so that no harmful contaminants remain.',
    }

    const data4 = {
        image: PreventOil,
        title: 'Prevent oil contamination',
        description: 'Even fresh oil is not 100 % clean. Residues from production, filling or transport may remain. Levels of contamination may be only slightly better than the specified minimum ISO requirement 21/19/16. This is not adequate for precisely designed hydraulic systems, because even the smallest amount of dirt in the micrometre range can lead to serious problems. Highly-sensitive hydraulic systems in machines and plant engineering equipment require special fluid care. If necessary, the fluid experts from HANSA-FLEX perform microfiltration of the oil before filling and officially record compliance with the required cleanliness level.',
    }

    const data5 = {
        image: Service1,
        description: 'Oil care is a key factor in ensuring trouble-free operation of hydraulic systems and plants. The fluid experts from HANSA-FLEX are there to advise you on all oil-related matters.',
        listItems: ["Oil analysis on site and in the laboratory",
            "Oil change and oil filtration, including filtering fresh oil",
            "Flushing of components and systems, dewatering oils",
            "Conversion from mineral-based oil to bio-oil",
            "Selection, design and installation of sensors and filter systems"]
    }

    const data6 = {
        image: Service2,
        description: 'You can rely on the proven HANSA-FLEX quality when it comes to filters and filter elements to ensure a longer service live, improved reliability, cost-saving operation and greater efficiency of your systems.',
        listItems: ["Flushing units and mobile bypass filter systems",
            "Suction and pressure filters",
            "Return flow filters, duplex, spin-on filters",
            "For sale or hire"]
    }

    // features card carousel data
    const featuresData = [
        {
            title: "On the road with experience and a laboratory",
            description: "Our fluid specialists take to the road with extensive experience and a mobile laboratory.",
            image: imgMark
        },
        {
            title: "Certified quality",
            description: "Work performed in compliance with the German Water Resources Act (WHG); ISO 9001; ISO 14001 and OHSAS 18001.",
            image: imgMark
        },
        {
            title: "Professional damage analysis",
            description: "We can tell a great deal about the likely damage to hydraulic components from the residues in the oil.",
            image: imgMark
        },
        {
            title: "Perfect advice",
            description: "We advise you on the selection and optimisation of hydraulic systems and fluids.",
            image: imgMark
        }

        // Add more objects for additional FeaturesCard data
    ];

    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={Stage}
                    title={"OIL FILTRATION ENSURES CLEAN FLUID AND EFFICIENT PROCESSES"}
                    paragraph={"Solid and liquid contaminants cause abrasion, increase wear and promote corrosion. Regular oil filtration ensures your production processes run smoothly and helps avoid damage to equipment, systems and machines. The fluid experts from HANSA-FLEX perform professional oil cleaning to achieve the desired cleanliness level and find the perfect filter solution for your hydraulic system."}
                />
            </div>

            <div>
                <SectionHeader
                    title={'WHY YOU SHOULD CARRY OUT REGULAR OIL FILTERING'}
                    subtitle={'Prevent machine downtimes and defective components'}
                />
                <ImageText
                    data={data1} />
                <ImageText
                    data={data2} left={false} />
                <ImageText
                    data={data3} />
                <ImageText
                    data={data4} left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"HANSA-FLEX FLUID SERVICE"}
                    subtitle={"From hydraulic oil analysis and cleaning right up to fluid-related system optimisation, the experienced fluid experts from HANSA-FLEX can advise and assist on all matters of fluid management."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={'FLUID SERVICES AND PRODUCTS'}
                    subtitle={'As experts in fluid technology, we have extensive experience in all aspects of hydraulic oils.'}
                />
                <ImageText
                    data={data5} />
                <ImageText
                    data={data6} left={false} />
            </div>
{/* 
            <div>
                <SectionHeader
                    title={'OIL-RELATED SERVICES'}
                    subtitle={'HANSA-FLEX Fluid Service carries out oil analyses directly on site, performs oil cleaning or complete oil changes.'}
                />
            </div> */}

        </div>
    )
}

export default OilFilteringAndCleaning