import React, { useEffect } from 'react'
import CenterStage from '../../../../Components/CenterStage/CenterStage'
import SectionHeader from '../../../../Components/SectionHeader/SectionHeader'
import ImageText from '../../../../Components/ImageText/ImageText'
import imgMark from '../../../../assets/mark.png';
import FeatureCardCarousel from '../../../../Components/FeaturesCardCarousal/FeatureCardCarousal';
import HFTabs from '../../../../Components/HfTabs/HfTabs';
import ImageCard from '../../../../Components/ImageCard/ImageCard';
import { Col, Container, Row } from 'react-bootstrap';

import imgStage from '../../../../assets/Products/DriveAndControlTechnology/PipelineInstallationValve/Stage.jpg'
import imgComprehensiveSupport from '../../../../assets/Products/DriveAndControlTechnology/PipelineInstallationValve/ComprehensiveSupport.jpg'
import imgRightAccessories from '../../../../assets/Products/DriveAndControlTechnology/PipelineInstallationValve/RightAccessories.jpg'
import imgPurchasePipeline from '../../../../assets/Products/DriveAndControlTechnology/PipelineInstallationValve/PurchasePipeline.jpg'
import imgShutOffValve from '../../../../assets/Products/DriveAndControlTechnology/PipelineInstallationValve/ShutOffValve.jpg'
import imgPressureCOntrolValve from '../../../../assets/Products/DriveAndControlTechnology/PipelineInstallationValve/PressureCOntrolValve.jpg'
import imgDirectionalControlValve from '../../../../assets/Products/DriveAndControlTechnology/PipelineInstallationValve/DirectionalControlValve.jpg'
import imgChangeOverValve from '../../../../assets/Products/DriveAndControlTechnology/PipelineInstallationValve/ChangeOverValve.jpg'
import imgCompactvalve from '../../../../assets/Products/DriveAndControlTechnology/PipelineInstallationValve/Compactvalve.jpg'
import imgCetopValve from '../../../../assets/Products/DriveAndControlTechnology/PipelineInstallationValve/CetopValve.jpg'
import imgMoreEfficiency from '../../../../assets/Products/DriveAndControlTechnology/PipelineInstallationValve/MoreEfficiency.jpg'



const PipelineInstallationValve = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const PipelineInstallationValve1 = {
        image: imgComprehensiveSupport,
        title: 'Comprehensive support from design to production',
        description: 'Our knowledgeable and experienced fluid technology specialists are delighted to assist and advise you in the selection of the most suitable pipeline installation valve for the required functions and for the intended field of use. We have valves suitable for use with ester-based fluids or water-glycol mixtures not complying with the requirements for hydraulic mineral oils in DIN 51524. In addition to the extensive HANSA-FLEX standard product programme, we can work in close cooperation with our partners to produce customer-specific component groups for any requirements.',
    }

    const PipelineInstallationValve2 = {
        image: imgRightAccessories,
        title: 'The right accessories for your pipeline installation valves',
        description: 'The HANSA-FLEX online shop contains suitable accessories for our pipeline installation valves. They have been designed to enable quick installation and safe operation.',
        listItems: ["Electrical plugs and solenoid coils", "Adjusting screws, seal, bolt and screw sets³", "Actuators and nuts such as counter nuts in steel"],
        buttonName: "TO THE ACCESSORIES"
    }

    const PipelineInstallationValve3 = {
        image: imgPurchasePipeline,
        description: 'Our range of products includes radial piston pumps and vane pumps for special useage scenarios. They are capable of creating pressures up to 500 bar for example.',
        listItems: ["Surface coating: burnished, phosphated, painted", "Materials: cast-iron, internal parts steel or aluminium", "Valves meeting all current standards", "Switching functions: electrical, hydraulic"],
    }

    // features card carousel data
    const featuresData = [
        {
            title: "Compact design",
            description: "Pipeline installation valves are known for their compact size and low weight.",
            image: imgMark
        },
        {
            title: "Easy to install",
            description: "Their simple design makes pipeline installation valves easy to install.",
            image: imgMark
        },
        {
            title: "Efficient solution",
            description: "Perfect for controlling small and large flows in hydraulic circuits.",
            image: imgMark
        },
        {
            title: "High-quality products",
            description: "The high quality of these valves ensures they operate reliably for the whole of their service life",
            image: imgMark
        },
        // Add more objects for additional FeaturesCard data
    ];

    //HfTab data
    const imageTextData = [
        {
            title: "Valves for shutting off the flow",
            description: "Pipeline installation valves with shut-off functions are designed as seat valves with cones or balls. They reliably shut off the flow using an internal spring.",
            listItems: ["Non-return valves", "Single and double pilot-operated non-return valves", "Shock valves", "Shuttle valves"],
            buttonName: "ALL HYDRAULIC TANKS",
            image: imgShutOffValve
        },
        {
            title: "Valves for regulating system pressure",
            description: "Pressure control valves of various types regulate or limit the pressure in the main or subsystems of a hydraulic system. They can also be used to perform signal pressure-dependent switching processes.",
            listItems: ["Pressure relief and pressure sequence valves", "Load holding valves", "Flow control valves", "Throttle valves and throttle non-return valves"],
            image: imgPressureCOntrolValve
        },
        {
            title: "Valves to control volumetric flow",
            description: "Directional control valves are electrically, pneumatically or hydraulically controlled and direct the flow in specific directions. The valve ports connect to or disconnect from actuators and control their movements.",
            listItems: ["3/2-way solenoid-operated directional control valve (diverter valve)", "6/2-way solenoid-operated directional control valve (diverter valve)", "Monoblock hand-operated directional control valves (up to 7 sections)"],
            image: imgDirectionalControlValve
        },
        {
            title: "Manual control of the flow",
            description: "In contrast to solenoid-operated directional control valves, flow control using flow deflect or similar types of changeover valves is done without external controls. The valve is actuated mechanically by a hand lever.",
            listItems: ["3/3-way changeover valve", "4/3-way changeover valve", "6/3-way changeover valve", "8/3-way changeover valve"],
            image: imgChangeOverValve
        },
    ];

    const tabTitles = [
        { title: "Shut-off valves", eventKey: "Shut-off valves" },
        { title: "Pressure control valves", eventKey: "Pressure control valves" },
        { title: "Directional control valves", eventKey: "Directional control valves" },
        { title: "Changeover valves", eventKey: "Changeover valves" },
    ];


    return (
        <div>
            <div>
                <CenterStage
                    imageSrc={imgStage}
                    title={"COMPACT AND COST-EFFECTIVE PIPELINE INSTALLATION VALVES FROM HANSA-FLEX"}
                    paragraph={"Pipeline installation valves are fitted directly into hydraulic system pipelines and perform basic functions such as limiting pressure or controlling flow. The compact valves come without additional housings or base plates. HANSA-FLEX has a wide portfolio of standardised hydraulic valves manufactured from high-grade materials with various coatings for pipeline installation for pressure ranges up to 350 bar."}
                />
            </div>

            <div>
                <SectionHeader
                    title={"PRODUCTS AND SERVICES FOR VALVE TECHNOLOGY"}
                />
                <ImageText
                    data={PipelineInstallationValve1} />

                <ImageText
                    data={PipelineInstallationValve2}
                    left={false} />
            </div>

            <div>
                <SectionHeader
                    title={"FOR BASIC CONTROL FUNCTIONS IN HYDRAULIC SYSTEMS"}
                    subtitle={"Pipeline installation valves are easy to install, inexpensive and simple to adjust, which makes them suitable for many types of control circuits and applications."}
                />
                <FeatureCardCarousel
                    featuresData={featuresData} />
            </div>

            <div>
                <SectionHeader
                    title={"PURCHASE PIPELINE INSTALLATION VALVES FROM HANSA-FLEX ONLINE"}
                    subtitle={"Our online shop offers a large selection of various pipeline installation valves, including flow and shock valves and suitable accessories."}
                />
                <ImageText
                    data={PipelineInstallationValve3} />
            </div>

            <div>
                <SectionHeader
                    title={"HANSA-FLEX PIPELINE INSTALLATION VALVES FOR ALL APPLICATIONS"}
                />
                <HFTabs imageTextData={imageTextData} tabTitles={tabTitles} />
            </div>

            <div>
                <SectionHeader
                    title={"THESE TOPICS MAY ALSO BE OF INTEREST TO YOU"}
                />
                <div>
                    <Container className="custom-container">
                        <Row>
                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgCompactvalve}
                                title="Compact cartridge valves for tight installation spaces"
                                desc={"Being directly installed into the valve block, a cartridge valve is a space-saving means of implementing complex controls without additional valve housings."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgCetopValve}
                                title="Implement a diverse range of valve functions with CETOP valves"
                                desc={"With their standardised plate mounting design, CETOP valves can be combined in all sorts of ways to implement a wide range of functions."} /></Col>

                            <Col sm={12} md={6} lg={4} ><ImageCard image={imgMoreEfficiency}
                                title="More efficiency for your systems and machines"
                                desc={"HANSA-FLEX Industrial Service helps you optimise your valve technology so that your existing systems and machines work more efficiently."} /></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

export default PipelineInstallationValve