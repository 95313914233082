import React, { useEffect } from 'react';
import ContactUsCard from '../../Components/ContactUsCard/ContactUsCard';
import CenterStage from '../../Components/CenterStage/CenterStage';
import imgCanadacontact from '../../assets/ContactLocation/Canadacontact.png';
import imgCanada from '../../assets/ContactLocation/canadaloc.png';

const ContactUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    const units = [
        {
            name: 'HANSA-FLEX Hydraulics Canada Inc.',
            location: '3-295 Connie Crescent, Concord, ON L4K 5R2, Canada',
            telephone: '(+1) 905 760 2224',
            // fax: "+1 713 466 8030",
            mail: 'cac@hansa-flex.com',
            image: imgCanada
        },
    ];

    return (
        <div className="">
            <div>
                <CenterStage
                    imageSrc={imgCanadacontact}
                    title={"Get in touch with HANSA-FLEX Hydraulics Canada Inc."}
                    paragraph={"HANSA-FLEX Hydraulics Canada Inc., a vital part of the esteemed HANSA-FLEX Group. Originally operating as Hatec International Inc., each branch is dedicated to providing top-tier hydraulic solutions and services, ensuring customers have access to the best in the industry. With a strong presence across various states, HANSA-FLEX Hydraulics Canada Inc. remains committed to supporting the hydraulic needs of industries nationwide."} />
            </div>
            <div className=''>
                <ContactUsCard units={units} />
            </div>
        </div>
    );
};

export default ContactUs;
